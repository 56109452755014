import styled from 'styled-components';
import { colors, fonts } from 'style/variables';

interface Props {
  amount: number;
}

const TextInputStyle = styled.div`
  margin-bottom: 1.6rem;
  font-family: ${fonts.secondary};
  font-size: 1.4rem;
  color: ${colors.black};

  div {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
      margin-top: 2rem;
      margin-left: .8rem;
      color: ${colors.disabled};
      will-change: margin-top, color, font-size;
      transition: margin-top .2s ease, color .2s ease, font-size .2s ease;
    }

    input {
      padding-left: .8rem;
      border: none;
      border-bottom: 1px solid ${colors.disabled};
      font-family: inherit;
      font-size: inherit;
      height: .4rem;
      color: transparent;
      will-change: height, border-bottom;
      transition: height .2s ease, border-bottom .2s ease;

      ::placeholder {
        color: transparent;
        will-change: color;
        transition: color .2s ease;
      }
    }
  }

  .focus, .error, .success {
    label {
      margin-top: 0;
      font-size: 1.2rem;
    }

    input {
      outline: none;
      height: 2.4rem;
      color: ${colors.black};

      ::placeholder {
        color: ${colors.disabled};
      }
    }
  }

  .error {
    label {
      color: ${colors.error};
    }

    input {
      border-bottom: 1px solid ${colors.error};
    }
  }

  .success {
    label {
      color: ${colors.success};
    }

    input {
      border-bottom: 1px solid ${colors.success};
    }
  }
`;

export const TextInputGroup = styled.div<Props>`
  display: flex;
  justify-content: space-between;

  ${TextInputStyle} {
    width: ${({ amount }): string => `calc(( 100% / ${amount} ) - 0.4rem)`};
  }
`;

export default TextInputStyle;
