import styled from 'styled-components';
import { colors } from 'style/variables';
import Card from 'style/Card';
import { animated } from 'react-spring';

const PopupStyle = styled(animated.div)`
  width: 50.4rem;
  position: fixed;
  bottom: 4rem;
  right: 4rem;
  z-index: 1;

  ${Card} {
    padding: 2.4rem 3.2rem;
    margin-bottom: 0;
  }

  h1, h2, h3, h4, p {
    color: ${colors.black};
  }

  @media (max-width: 600px) {
    width: 90vw;
    bottom: 5vw;
    right: 5vw;
  }
`;

export default PopupStyle;
