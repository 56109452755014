import React, { FunctionComponent } from 'react';
import Card from 'style/Card';
import ConceptPageCardsStyle from './ConceptPageCardsStyle';

const ConceptPageCards: FunctionComponent = () => (
  <ConceptPageCardsStyle>
    <div className="card">
      <Card image="havana_bleached3.jpg" />
      <div className="card-text">
        <p>Did you ever put your</p>
        <h3>Phone in your back pocket</h3>
        <p>
            I&apos;m sure there was at least&nbsp;
          <br />
            one moment where you just knew that&nbsp;
          <br />
            wasn&apos;t safe, or where you questioned why&nbsp;
          <br />
            women&apos;s pockets are so damn small.&nbsp;
          <br />
            Well... We don&apos;t know either...
        </p>
      </div>
    </div>
    <div className="card">
      <Card image="havana_bleached1.jpg" />
    </div>
    <div className="card">
      <div className="card-text">
        <p>Or are you guilty of putting your</p>
        <h3>Wallet in your front pocket</h3>
        <p>
            Ofcourse you are, but do you know why?&nbsp;
          <br />
            Probably because you were adventuring&nbsp;
          <br />
            in an unknown place and didn&apos;t want your&nbsp;
          <br />
            belongings stolen from you!
        </p>
      </div>
      <Card image="havana_bleached2.jpg" />
    </div>
  </ConceptPageCardsStyle>
);

export default ConceptPageCards;
