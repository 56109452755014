import importAll from './importAll';

export interface ImageSizes {
  [size: string]: string;
}

const getImageSizes = (filename: string): ImageSizes => {
  const file = {
    name: filename.split('.')[0],
    extension: filename.split('.')[1],
  };
  const images: { [size: string]: string } = {};
  const originalFiles = importAll(require.context('../assets/img/', false, /\.jpg$/), `${file.name}_`, true);
  const sizes = originalFiles.map((originalFileName) => parseInt(
    originalFileName.substring(
      originalFileName.lastIndexOf(`/${file.name}_`) + `/${file.name}_`.length,
      originalFileName.lastIndexOf(`.${file.extension}`),
    ), 10,
  ));

  for (let i = 0; i < sizes.length; i += 1) {
    const { name, extension } = file;

    const [image] = importAll(
      require.context('../assets/img/', false, /\.jpg$/),
      `${name}_${sizes[i]}.${extension}`,
    );

    if (sizes[i]) {
      images[sizes[i]] = image;
    }
  }

  return images;
};

export default getImageSizes;
