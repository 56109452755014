import React, { FunctionComponent } from 'react';
import Header from 'components/navigation/header/Header';
import Button from 'style/Button';
import ErrorPageStyle from './ErrorPageStyle';

interface Props {
  id?: '404';
  resetError?(): void;
}

const renderContent = (id?: '404'): { title: string; body: string } => {
  switch (id) {
    case '404':
      return {
        title: 'Page not found!',
        body: "Looks like this page doesn't exist... We are very sorry. Maybe visit our homepage instead?",
      };
    default:
      return {
        title: 'Oops!',
        body: "Looks like something went wrong! Don't worry, we'll fix this as soon as possible! In the meantime you could visit our homepage.",
      };
  }
};

const ErrorPage: FunctionComponent<Props> = ({ resetError, id }) => (
  <ErrorPageStyle image="error_bg.jpg">
    <Header theme="white" />
    <h1>{renderContent(id).title}</h1>
    <p>{renderContent(id).body}</p>
    <Button to="/" onClick={resetError}>Home</Button>
  </ErrorPageStyle>
);

export default ErrorPage;
