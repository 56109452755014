export interface Image {
  [color: string]: string;
}

export interface Color {
  name: string;
  hex: string;
}

export interface Info {
  title: string;
  info: string;
  cta: string;
}

export interface Feature {
  title: string;
  image: Image;
}

export interface Sku {
  attributes: {
    size: string;
    color: string;
    gender: 'Female' | 'Male';
  };
  currency: 'eur';
  price: number;
  inventory: number;
}

export interface Product {
  url: string;
  name: string;
  colors: Color[];
  images: Image[];
  features: Feature[];
  info: Info[];
  skus: Sku[];
}

const collection: Product[] = [
  {
    url: 'women',
    name: 'Havana',
    colors: [
      {
        name: 'Stone bleach',
        hex: '#b8c8d6',
      },
      {
        name: 'Light blue',
        hex: '#9cadc4',
      },
    ],
    images: [
      {
        'Stone bleach': 'havana_bleached_back.jpg',
        'Light blue': 'havana_blue_back.jpg',
      },
      {
        'Stone bleach': 'havana_bleached_front.jpg',
        'Light blue': 'havana_blue_front.jpg',
      },
    ],
    features: [
      {
        title: 'Pickpocket proof',
        image: {
          'Stone bleach': 'havana_bleached1.jpg',
          'Light blue': 'havana_blue1.jpg',
        },
      },
      {
        title: 'Improved comfort',
        image: {
          'Stone bleach': 'havana_bleached3.jpg',
          'Light blue': 'havana_blue3.jpg',
        },
      },
      {
        title: 'Supersized pockets',
        image: {
          'Stone bleach': 'havana_bleached2.jpg',
          'Light blue': 'havana_blue2.jpg',
        },
      },
    ],
    info: [
      {
        title: '',
        info: '',
        cta: '',
      },
      {
        title: '',
        info: '',
        cta: '',
      },
    ],
    skus: [
      {
        attributes: {
          size: '30',
          color: 'Stone bleach',
          gender: 'Female',
        },
        currency: 'eur',
        price: 5995,
        inventory: 1,
      },
      {
        attributes: {
          size: '32',
          color: 'Stone bleach',
          gender: 'Female',
        },
        currency: 'eur',
        price: 5995,
        inventory: 1,
      },
      {
        attributes: {
          size: '34',
          color: 'Stone bleach',
          gender: 'Female',
        },
        currency: 'eur',
        price: 5995,
        inventory: 1,
      },
      {
        attributes: {
          size: '36',
          color: 'Stone bleach',
          gender: 'Female',
        },
        currency: 'eur',
        price: 5995,
        inventory: 1,
      },
      {
        attributes: {
          size: '38',
          color: 'Stone bleach',
          gender: 'Female',
        },
        currency: 'eur',
        price: 5995,
        inventory: 1,
      },
      {
        attributes: {
          size: '40',
          color: 'Stone bleach',
          gender: 'Female',
        },
        currency: 'eur',
        price: 5995,
        inventory: 1,
      },
      {
        attributes: {
          size: '42',
          color: 'Stone bleach',
          gender: 'Female',
        },
        currency: 'eur',
        price: 5995,
        inventory: 1,
      },
      {
        attributes: {
          size: '30',
          color: 'Light blue',
          gender: 'Female',
        },
        currency: 'eur',
        price: 5995,
        inventory: 1,
      },
      {
        attributes: {
          size: '32',
          color: 'Light blue',
          gender: 'Female',
        },
        currency: 'eur',
        price: 5995,
        inventory: 1,
      },
      {
        attributes: {
          size: '34',
          color: 'Light blue',
          gender: 'Female',
        },
        currency: 'eur',
        price: 5995,
        inventory: 1,
      },
      {
        attributes: {
          size: '36',
          color: 'Light blue',
          gender: 'Female',
        },
        currency: 'eur',
        price: 5995,
        inventory: 1,
      },
      {
        attributes: {
          size: '38',
          color: 'Light blue',
          gender: 'Female',
        },
        currency: 'eur',
        price: 5995,
        inventory: 1,
      },
      {
        attributes: {
          size: '40',
          color: 'Light blue',
          gender: 'Female',
        },
        currency: 'eur',
        price: 5995,
        inventory: 1,
      },
      {
        attributes: {
          size: '42',
          color: 'Light blue',
          gender: 'Female',
        },
        currency: 'eur',
        price: 5995,
        inventory: 1,
      },
    ],
  },
  {
    url: 'men',
    name: 'Brooklyn',
    colors: [
      {
        name: 'Grey',
        hex: '#9e9e9f',
      },
      {
        name: 'Mid blue',
        hex: '#547891',
      },
    ],
    images: [
      {
        Grey: 'brooklyn_grey_back.jpg',
        'Mid blue': 'brooklyn_blue_back.jpg',
      },
      {
        Grey: 'brooklyn_grey_front.jpg',
        'Mid blue': 'brooklyn_blue_front.jpg',
      },
    ],
    features: [
      {
        title: 'Pickpocket proof',
        image: {
          Grey: 'brooklyn_grey1.jpg',
          'Mid blue': 'brooklyn_blue1.jpg',
        },
      },
      {
        title: 'Improved comfort',
        image: {
          Grey: 'brooklyn_grey2.jpg',
          'Mid blue': 'brooklyn_blue2.jpg',
        },
      },
      {
        title: 'Supersized pockets',
        image: {
          Grey: 'brooklyn_grey3.jpg',
          'Mid blue': 'brooklyn_blue3.jpg',
        },
      },
    ],
    info: [
      {
        title: '',
        info: '',
        cta: '',
      },
      {
        title: '',
        info: '',
        cta: '',
      },
    ],
    skus: [
      {
        attributes: {
          size: '28',
          color: 'Grey',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 1,
      },
      {
        attributes: {
          size: '29',
          color: 'Grey',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 1,
      },
      {
        attributes: {
          size: '30',
          color: 'Grey',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 1,
      },
      {
        attributes: {
          size: '31',
          color: 'Grey',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 1,
      },
      {
        attributes: {
          size: '32',
          color: 'Grey',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 1,
      },
      {
        attributes: {
          size: '33',
          color: 'Grey',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 1,
      },
      {
        attributes: {
          size: '34',
          color: 'Grey',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 1,
      },
      {
        attributes: {
          size: '35',
          color: 'Grey',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 0,
      },
      {
        attributes: {
          size: '36',
          color: 'Grey',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 0,
      },
      {
        attributes: {
          size: '28',
          color: 'Mid blue',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 1,
      },
      {
        attributes: {
          size: '29',
          color: 'Mid blue',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 1,
      },
      {
        attributes: {
          size: '30',
          color: 'Mid blue',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 1,
      },
      {
        attributes: {
          size: '31',
          color: 'Mid blue',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 1,
      },
      {
        attributes: {
          size: '32',
          color: 'Mid blue',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 1,
      },
      {
        attributes: {
          size: '33',
          color: 'Mid blue',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 1,
      },
      {
        attributes: {
          size: '34',
          color: 'Mid blue',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 1,
      },
      {
        attributes: {
          size: '35',
          color: 'Mid blue',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 0,
      },
      {
        attributes: {
          size: '36',
          color: 'Mid blue',
          gender: 'Male',
        },
        currency: 'eur',
        price: 6995,
        inventory: 0,
      },
    ],
  },
];

export default collection;
