import styled from 'styled-components';
import { colors, fonts } from 'style/variables';
import Wrapper from 'style/Wrapper';

const FooterStyle = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;

  a {
    color: ${colors.black};
    transition: margin-left 0.3s ease;

    :hover {
      margin-left: 1.6rem;
    }
  };

  li {
    width: 17rem;
    margin: 0 0 1.6rem 4rem;
  };

  .link-list {
    display: flex;
    align-items: flex-start;
  };

  .title {
    position: absolute;
    font-family: ${fonts.secondary};
    color: ${colors.black};
    text-transform: uppercase;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: top left;
  };

  @media (max-width: 700px) {
    
    li {
      width: 14rem;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;

    .link-list {
      margin-bottom: 4rem;
    }
  }
`;

export default FooterStyle;
