import { Reducer } from 'react';
import { Action } from '../actions/action';
import { MainState, initialMainState } from '../initialState';
import { isResizeAction, ResizeAction } from '../actions/mainActions/resize';
import isEqual from '../isEqual';

const mainReducer: Reducer<MainState, Action> = (
  state: MainState = initialMainState, action: Action,
) => {
  if (isResizeAction(action) && !isEqual<ResizeAction>(action, state)) {
    return {
      clientWidth: action.clientWidth,
      clientHeight: action.clientHeight,
    };
  }

  return state;
};

export default mainReducer;
