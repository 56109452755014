import termsConditions from '../pdf/Terms_Conditions.pdf';
import privacyPolicy from '../pdf/Privacy_Policy.pdf';
import returnProcess from '../pdf/Return_Process.pdf';
import impressum from '../pdf/Impressum.pdf';

interface SocialLink {
  image: string;
  alt: string;
  url: string;
}

interface FooterSublink {
  name: string;
  url?: string;
  modal?: string;
}

interface FooterLink {
  title: string;
  links: FooterSublink[];
}

interface Links {
  social: SocialLink[];
  footer: FooterLink[];
}

const links: Links = {
  social: [
    {
      image: 'twitter',
      alt: 'Twitter',
      url: 'https://www.twitter.com/TiretteLabel/',
    },
    {
      image: 'instagram',
      alt: 'Instagram',
      url: 'https://www.instagram.com/tirettelabel/',
    },
    {
      image: 'facebook',
      alt: 'Facebook',
      url: 'https://www.facebook.com/tirettelabel/',
    },
  ],
  footer: [
    {
      title: 'Contact us',
      links: [
        {
          name: 'info@tirette.com',
          url: 'mailto:info@tirette.com',
        },
        {
          name: 'sales@tirette.com',
          url: 'mailto:sales@tirette.com',
        },
        {
          name: 'privacy@tirette.com',
          url: 'mailto:privacy@tirette.com',
        },
      ],
    },
    {
      title: 'Customer service',
      links: [
        {
          name: 'Return items',
          url: returnProcess,
        },
        {
          name: 'Shipping',
          modal: 'shipping',
        },
      ],
    },
    {
      title: 'Information',
      links: [
        {
          name: 'Privacy policy',
          url: privacyPolicy,
        },
        {
          name: 'Terms & Conditions',
          url: termsConditions,
        },
        {
          name: 'Impressum',
          url: impressum,
        },
        {
          name: 'Copyright notice',
          modal: 'copyright',
        },
      ],
    },
  ],
};

export default links;
