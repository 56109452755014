import useGlobalState from './globalState/useGlobalState';

const useAboveTheFoldHeight = (): '100vh' | '85rem' | '50rem' => {
  const { clientWidth, clientHeight } = useGlobalState('main');

  switch (true) {
    case clientWidth > 800 && clientHeight <= 850: return '85rem';
    case clientWidth <= 800 && clientHeight <= 500: return '50rem';
    default: return '100vh';
  }
};

export default useAboveTheFoldHeight;
