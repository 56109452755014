import styled from 'styled-components';
import Card from 'style/Card';
import { colors } from 'style/variables';

const ConceptPageCardsStyle = styled.section`

  ${Card} {
    width: calc(33% + 2.4rem);
    padding-top: calc((33% + 2.4rem) * 1.63);
  }

  h3 {
    color: ${colors.black};
  }

  .card {
    width: 100%;
    display: flex;
    margin-top: -32rem;
    justify-content: space-between;

    :first-child {
      margin-top: -5.2rem;
    }

    :nth-child(2) {
      justify-content: center;
    }

    :last-child {
        
      .card-text {
        margin-top: 32.9rem;
      }
    }

    .card-text {
      margin-top: 13.6rem;
      width: 45%;
      z-index: 1;
        
      p, h3 {
        color: ${colors.black};
      }

      h3 {
        text-align: left;
        margin: 0;
      }
    }
  }

  @media (max-width: 1200px) {
    .card {
      margin-top: -26.667vw;

      .card-text {
        margin-top: 10.5vw;
      }

      :last-child {
        .card-text {
          margin-top: 27.4vw;
        }
      }
    }
  }

  @media (max-width: 800px) {
    h3 {
      font-size: 5vw;
    }

    br {
      display: none;
    }

    .card {
      margin-top: -15vw;
    }
  }

  @media (max-width: 500px) {
    ${Card} {
      width: 100%;
      padding-top: calc(100% * 1.63);
    }

    h1 {
      font-size: 15vw;
      line-height: 18vw;
    }

    h3 {
      font-size: 2.5rem;
    }

    br {
      visibility: visible;
    }

    .card {
      flex-direction: column;

      .card-text {
        align-self: center;
        width: 60%;
        margin-bottom: 10rem;

        p, h3 {
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 400px) {
    .card .card-text {
      width: 80%;
    }
  }
`;

export default ConceptPageCardsStyle;
