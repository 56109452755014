import './style/global.css';
import '@babel/polyfill';

import React, { useEffect, FunctionComponent } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { render } from 'react-dom';
import WebFont from 'webfontloader';

import { loadStripe } from '@stripe/stripe-js';
import { resize as resizeAction } from 'hooks/globalState/actions/mainActions/resize';
import ErrorBoundary from 'components/ErrorBoundary';
import Footer from 'components/navigation/footer/Footer';
import ScrollToTop from 'components/ScrollToTop';
import GlobalStateProvider from 'hooks/globalState/GlobalStateProvider';
import debounce from 'utils/debounce';
import Modal from 'components/modal/Modal';
import { fonts } from 'style/variables';
import useDispatch from 'hooks/globalState/useDispatch';
import ConsentBox from 'components/popup/consentBox/ConsentBox';
import Routes from './Routes';

const App: FunctionComponent = () => {
  const resize = useDispatch(resizeAction);

  WebFont.load({
    google: {
      families: [fonts.secondary],
    },
  });

  const onResize = debounce(() => {
    resize({
      clientWidth: window.innerWidth,
      clientHeight: window.innerHeight,
    });
  }, 100, true);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return (): void => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return (
    <ErrorBoundary>
      <Switch>
        <Route render={(): JSX.Element => (
          <>
            <Routes />
            <Footer />
            <ConsentBox />
            <Modal />
          </>
        )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

render(
  <BrowserRouter>
    <ScrollToTop>
      <Elements stripe={loadStripe(process.env.STRIPE_PUBLISHABLE_KEY as string)} options={{ locale: 'en' }}>
        <GlobalStateProvider>
          <App />
        </GlobalStateProvider>
      </Elements>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('app'),
);
