import React, { FunctionComponent, useState, useMemo } from 'react';
import Slider from 'components/slider/Slider';
import importAll from 'utils/importAll';
import SliderButton from 'components/slider/SliderButton';
import ConceptPageDemoStyle from './ConceptPageDemoStyle';

const ConceptPageDemo: FunctionComponent = () => {
  const [flatBack] = importAll(require.context('../../../../assets/svg/', false, /\.svg$/), 'flat_back.svg');
  const [sliderIndex, setSliderIndex] = useState(0);
  const [sliderTimerActive, setSliderTimerActive] = useState(true);
  const slides = [
    {
      title: "We've got your ass covered",
      text: "Tirette's unique design protects all your valuables against theft with a simple, extended zipper closing both your front- and back pockets at once. This makes it practically impossible to pickpocket you unnoticed.",
    },
    {
      title: 'Super-size what matters',
      text: "Plus-size pockets, even for the women's jeans. All the pro's of cargo shorts, minus the contra's. Our phones get bigger every year, so we made sure they fit in your brand spanking new pair of jeans.",
    },
    {
      title: 'Better sitting comfort',
      text: "Ever tried to sit down with a huge-ass phone in your front pocket? Well... it doesn't go too well... Tirette Jeans' inner pockets are slightly curved, which pushes every item in your front pocket to the side when sitting down... problem solved!",
    },
  ];

  return (
    <ConceptPageDemoStyle>
      <h3>Here&apos;s what you can do!</h3>
      <div className="clip">
        {useMemo(() => (
          <div>
            <img src={flatBack} alt="Tirette jeans" width="852" />
          </div>
        ), [flatBack])}
        <div>
          <Slider
            index={sliderIndex}
            setIndex={setSliderIndex}
            timerActive={sliderTimerActive}
          >
            {slides.map(((slide) => (
              <article key={slide.title}>
                <h3>{slide.title}</h3>
                <p>{slide.text}</p>
              </article>
            )))}
          </Slider>
          <div className="slider-controls">
            {slides.map((slide, i) => (
              <SliderButton
                key={slide.title}
                slideId={i}
                setIndex={setSliderIndex}
                activeSlideId={sliderIndex}
                deactivateTimer={(): void => setSliderTimerActive(false)}
              />
            ))}
          </div>
        </div>
      </div>
    </ConceptPageDemoStyle>
  );
};

export default ConceptPageDemo;
