import styled from 'styled-components';

import Button, { SecondaryButton } from 'style/Button';
import Card from 'style/Card';

const NewsCardStyle = styled.div`

  ${Card} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  ${Button} {
    margin-top: 3.2rem;
  }

  ${SecondaryButton} {
    width: 100%;
    justify-content: flex-end;
  }

  h2 {
    text-align: center;
    font-size: 6.4rem;
    width: 90%;

    ::after {
      content: '';
      position: absolute;
      width: 24%;
      height: 6.6rem;
      left: 38%;
      border-bottom: 0.1rem solid white;
    }
  }

  h4 {
    margin-top: 0.6rem;
  }

  p {
    padding: 4.25rem 7.15rem;
  }

  .header, .intro {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header {
    justify-content: center;
  }

  .intro {
    justify-content: space-between;
  }

  @media (max-width: 1200px) {

    h2 {
      font-size: 4.75vw;

      ::after {
        height: 4.9vw;
      }
    }

    p {
      padding: 2rem 3.35rem;
    }
  }

  @media (max-width: 800px) {

    h2 {
      font-size: 7vw;

      ::after {
        height: 7.2vw;
      }
    }

    p {
      padding: 3.5vw 6vw;
    }
  }

  @media (max-width: 450px) {

    h2 {
      font-size: 14vw;

      ::after {
        height: 14.4vw;
      }
    }

    p {
      padding: 7vw 12vw;
    }
  }
`;

export default NewsCardStyle;
