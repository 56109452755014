export interface SizeGuide {
  size: number;
  waist: number;
  hip: number;
}

export const maleSizeGuide: SizeGuide[] = [
  { size: 28, waist: 38, hip: 47 },
  { size: 29, waist: 39, hip: 48 },
  { size: 30, waist: 40, hip: 49 },
  { size: 31, waist: 41, hip: 50 },
  { size: 32, waist: 42, hip: 51 },
  { size: 33, waist: 43, hip: 52 },
  { size: 34, waist: 44, hip: 53 },
  { size: 35, waist: 45, hip: 54 },
  { size: 36, waist: 46, hip: 55 },
];

export const femaleSizeGuide: SizeGuide[] = [
  { size: 30, waist: 34, hip: 40 },
  { size: 32, waist: 35, hip: 41 },
  { size: 34, waist: 36, hip: 42 },
  { size: 36, waist: 37, hip: 43 },
  { size: 38, waist: 38, hip: 44 },
  { size: 40, waist: 39, hip: 45 },
  { size: 42, waist: 40, hip: 46 },
];
