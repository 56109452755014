const actionPrefix = 'FNS/';

const ActionTypes = {
  Resize: `${actionPrefix}MAIN/RESIZE`,
  ToggleHamburger: `${actionPrefix}NAVIGATION/TOGGLEHAMBURGER`,
  ActivateDropdown: `${actionPrefix}NAVIGATION/ACTIVATEDROPDOWN`,
  MoveIndicator: `${actionPrefix}NAVIGATION/MOVEINDICATOR`,
  SetModal: `${actionPrefix}MODAL/SETMODAL`,
};

export default ActionTypes;
