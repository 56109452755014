import styled from 'styled-components';
import Link from 'components/navigation/Link';
import { colors } from 'style/variables';

interface ButtonProps {
  dark?: boolean;
  disabled?: boolean;
}

interface SecondaryButtonProps extends ButtonProps {
  forward?: boolean;
  back?: boolean;
}

const getBgColor = (disabled?: boolean, dark?: boolean): string => {
  switch (true) {
    case disabled: return colors.disabled;
    case dark: return colors.black;
    default: return 'white';
  }
};

const getHoverBgColor = (disabled?: boolean, dark?: boolean): 'transparent' | '#111' | '#ccc' => {
  switch (true) {
    case disabled: return 'transparent';
    case dark: return '#111';
    default: return '#ccc';
  }
};

const Button = styled(Link).attrs({ 'data-testid': 'primary-button' })<ButtonProps>`
  width: 16.6rem;
  height: 3.6rem;
  text-decoration: none;
  border-radius: 1.8rem;
  background-color: ${({ disabled, dark }): string | 'white' => getBgColor(disabled, dark)};
  color: ${({ dark }): string => (dark ? 'white' : colors.black)};
  text-align: center;
  padding-top: 1.2rem;
  display: block;
  cursor: ${({ disabled }): 'default' | 'pointer' => (disabled ? 'default' : 'pointer')};

  ::after {
    content: '';
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 1.8rem;
    overflow: hidden;
    background-color: transparent;
    display: block;
    margin-top: -2.6rem;
    will-change: width, background-color;
    transition: width 0.5s ease, background-color 0.5s ease;
    opacity: 0.4;
  }

  :hover::after {
    width: 16.6rem;
    background-color: ${({ disabled, dark }): 'transparent' | '#111' | '#ccc' => getHoverBgColor(disabled, dark)};
  }
`;

export const SecondaryButton = styled(Link).attrs({ 'data-testid': 'secondary-button' })<SecondaryButtonProps>`
  color: ${({ dark }): string => (dark ? colors.black : 'white')};
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding-right: 1.6rem;
  margin-bottom: 1.6rem;
  cursor: pointer;

  ::before {
    content: '';
    display: ${({ back }): 'block' | 'none' => (back ? 'block' : 'none')};
    width: 2.4rem;
    height: .2rem;
    background-color: ${({ dark }): string => (dark ? colors.black : 'white')};
    margin-right: .8rem;
    will-change: margin-right, width;
    transition: margin-right .5s ease, width .5s ease;
  }

  ::after {
    content: '';
    display: ${({ forward }): 'block' | 'none' => (forward ? 'block' : 'none')};
    width: 2.4rem;
    height: .2rem;
    background-color: ${({ dark }): string => (dark ? colors.black : 'white')};
    margin-left: .8rem;
    will-change: margin-left, width;
    transition: margin-left .5s ease, width .5s ease;
  }

  :hover::before {
    width: 3.2rem;
    margin-right: 1.6rem;
  }

  :hover::after {
    width: 3.2rem;
    margin-left: 1.6rem;
  }
`;

export default Button;
