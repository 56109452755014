import React, { FunctionComponent, useRef } from 'react';
import { FormContextValues, FieldError } from 'react-hook-form';
import TextInputStyle from './TextInputStyle';

interface Props {
  register: FormContextValues['register'];
  watch: FormContextValues['watch'];
  errors: FormContextValues['errors'];
  id: string;
  label: string;
  placeholder: string;
  type?: string;
}

const TextInput: FunctionComponent<Props> = ({
  register, watch, errors, id, label, placeholder, type,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const error: FieldError | undefined = errors[id];

  const addClassName = (className?: 'focus' | 'error' | 'success'): void => {
    if (containerRef.current) {
      containerRef.current.classList.remove('focus', 'error', 'success');
      if (className) {
        containerRef.current.classList.add(className);
      }
    }
  };

  const onFocus = (): void => {
    if (labelRef.current) {
      labelRef.current.innerText = label;
    }
    addClassName('focus');
  };

  const onBlur = (): void => {
    if (error && labelRef.current) {
      addClassName('error');
      labelRef.current.innerText = (error.message as string) || label;
    } else if (watch(id) === '' && labelRef.current) {
      addClassName();
      labelRef.current.innerText = label;
    } else {
      addClassName('success');
    }
  };

  return (
    <TextInputStyle>
      <div ref={containerRef}>
        <label ref={labelRef} htmlFor={id}>{label}</label>
        <input
          ref={register}
          id={id}
          name={id}
          placeholder={placeholder}
          type={type}
          onFocus={onFocus}
          onBlur={onBlur}
          aria-invalid={!!error}
          aria-describedby={(error?.message as string)}
        />
      </div>
    </TextInputStyle>
  );
};

export default TextInput;
