import React, { useState, FunctionComponent, useEffect } from 'react';

import { useTransition, animated } from 'react-spring';
import { Image as ImageInterface } from 'assets/data/collection';
import Slider from 'components/slider/Slider';
import SliderButton from 'components/slider/SliderButton';
import useGlobalState from 'hooks/globalState/useGlobalState';
import Image from 'components/Image';
import { transition as config } from 'animationConfig';

interface Props {
  productName: string;
  headerImages: ImageInterface[];
  featureImages: ImageInterface[];
  color: string;
}

interface RenderImages {
  (images: ImageInterface[], alt: string, className: string, c: string): JSX.Element[];
}

const ProductPageHeader: FunctionComponent<Props> = ({
  productName, headerImages, featureImages, color,
}) => {
  const { clientWidth } = useGlobalState('main');
  const [sliderIndex, setSliderIndex] = useState(0);
  const [sliderTimerActive, setSliderTimerActive] = useState(true);
  const allImages = [...headerImages, ...featureImages];

  const transitions = useTransition(color, null, {
    config,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 9 },
  });

  const deactivateSliderTimer = (): void => setSliderTimerActive(false);

  const getImages = (): ImageInterface[] => (clientWidth > 800 ? headerImages : allImages);

  const renderImages: RenderImages = (images, alt, className, c) => {
    const hasColor = !!images[0][c];
    const backupColor = Object.keys(images[0])[0];
    const activeColor = hasColor ? c : backupColor;

    return (
      images.map((image) => (
        <Image key={image[activeColor]} className={className} src={image[activeColor]} alt={alt} />
      ))
    );
  };

  useEffect(() => {
    setSliderIndex(0);
  }, [clientWidth]);

  return (
    <>
      {clientWidth >= 1200 && (
        <div className="image-container">
          {transitions.map(({ item, key, props }) => (
            <animated.div className="image-animated-container" key={key} style={props}>
              {renderImages(headerImages, productName, 'header-image', item)}
            </animated.div>
          ))}
        </div>
      )}
      {clientWidth < 1200 && (
        <>
          <div className="slider-container">
            {transitions.map(({ item, key, props }) => (
              <animated.div className="slider-animated-container" key={key} style={props}>
                <Slider
                  index={sliderIndex}
                  setIndex={setSliderIndex}
                  timerActive={sliderTimerActive}
                >
                  {renderImages(getImages(), productName, 'slide-image', item)}
                </Slider>
              </animated.div>
            ))}
          </div>
          <div className="slider-controls">
            {getImages().map((image, i) => (
              <SliderButton
                key={image[color]}
                dark
                slideId={i}
                setIndex={setSliderIndex}
                activeSlideId={sliderIndex}
                deactivateTimer={deactivateSliderTimer}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default ProductPageHeader;
