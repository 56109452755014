import React, { useState, useMemo, FunctionComponent } from 'react';

import slideData from 'assets/data/slides';
import Button from 'style/Button';
import FeatureCard from 'components/cards/featureCard/FeatureCard';
import NewsCard from 'components/cards/newsCard/NewsCard';
import ProductCard from 'components/cards/productCard/ProductCard';
import Slider from 'components/slider/Slider';
import SliderButton from 'components/slider/SliderButton';
import Wrapper from 'style/Wrapper';
import cards from 'assets/data/cards';
import useGlobalState from 'hooks/globalState/useGlobalState';
import Image from 'components/Image';
import AboveTheFold from 'style/AboveTheFold';
import HomePageStyle, { Slide } from './HomePageStyle';

const HomePage: FunctionComponent = () => {
  const { clientWidth } = useGlobalState('main');
  const [sliderIndex, setSliderIndex] = useState(0);
  const [sliderTimerActive, setSliderTimerActive] = useState(true);
  const slides = slideData.home;

  const calcDivider = (): 1 | 2 | 3 => {
    switch (true) {
      case clientWidth > 800: return 3;
      case clientWidth > 450: return 2;
      default: return 1;
    }
  };

  const divider = calcDivider();

  return (
    <HomePageStyle divider={divider}>
      <AboveTheFold>
        <Slider
          index={sliderIndex}
          setIndex={setSliderIndex}
          timerActive={sliderTimerActive}
        >
          {slides.map((slide) => (
            <Slide key={slide.title}>
              <div>
                <p>{slide.subtitle}</p>
                <h1>{slide.title}</h1>
                <Button to={slide.url}>{slide.cta}</Button>
              </div>
              <Image src={slide.image} alt={slide.title} />
            </Slide>
          ))}
        </Slider>
        <div className="slider-controls">
          {slides.map((slide, i) => (
            <SliderButton
              key={slide.id}
              slideId={i}
              setIndex={setSliderIndex}
              activeSlideId={sliderIndex}
              deactivateTimer={(): void => setSliderTimerActive(false)}
            />
          ))}
        </div>
      </AboveTheFold>
      {useMemo(() => (
        <Wrapper className="cards-container">
          <FeatureCard
            card={cards.feature[0]}
          />
          <div className="card-container">
            {cards.product.map((card) => (
              <ProductCard card={card} key={card.title} />
            ))}
          </div>
          <div className="card-container">
            <div className="row">
              {cards.news.map((card, i) => (
                i % divider === 0 && <NewsCard key={card.title} card={card} />
              ))}
            </div>
            {clientWidth > 450 && (
              <div className="row">
                {cards.news.map((card, i) => (
                  i % divider === 1 && <NewsCard key={card.title} card={card} />
                ))}
              </div>
            )}
            {clientWidth > 800 && (
              <div className="row">
                {cards.news.map((card, i) => (
                  i % divider === 2 && <NewsCard key={card.title} card={card} />
                ))}
              </div>
            )}
          </div>
        </Wrapper>
      ), [clientWidth, divider])}
    </HomePageStyle>
  );
};

export default HomePage;
