import { Action } from '../actions/action';
import { GlobalState } from '../initialState';
import mainReducer from './mainReducer';
import navigationReducer from './navigationReducer';
import modalReducer from './modalReducer';

const rootReducer = ({ main, navigation, modal }: GlobalState, action: Action): GlobalState => ({
  main: mainReducer(main, action),
  navigation: navigationReducer(navigation, action),
  modal: modalReducer(modal, action),
});

export default rootReducer;
