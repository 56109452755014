import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import SelectInput from 'components/inputs/selectInput/SelectInput';
import countries from 'assets/data/countries';
import ShippingModalStyle from './ShippingModalStyle';

const ShippingModal: FunctionComponent = () => {
  const { register, errors, watch } = useForm({
    mode: 'onChange',
    defaultValues: { country: 'Belgium' },
  });

  const price = (amount: number): string => `€${amount / 100}`;
  const shippingAmount = countries.find(({ name }) => name === watch('country'))?.shippingRate;

  return (
    <ShippingModalStyle>
      <h3>Free shipping in Belgium.</h3>
      <p>
        We currently only ship to a few countries.
        Use the input field below to check if we ship to your country
        and if a shipping fee will apply to you.
      </p>
      <div className="input-container">
        <SelectInput register={register} errors={errors} watch={watch} id="country" label="country" options={countries.map(({ name }) => ({ name, value: name }))} />
        <p>
          {shippingAmount
            ? `${price(shippingAmount)} shipping will apply.`
            : 'Free shipping.'
          }
        </p>
      </div>
    </ShippingModalStyle>
  );
};

export default ShippingModal;
