import styled from 'styled-components';
import { SecondaryButton } from 'style/Button';
import { colors } from 'style/variables';

const NewspaperCardStyle = styled.article`
  width: calc(33.333% - 0.8rem);
  padding-top: calc((33.333% - 0.8rem) * 1.44);
  height: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.7);
  position: relative;
  backdrop-filter: blur(4px);

  ${SecondaryButton} {
    width: 100%;
    justify-content: flex-end;
  }

  h3, h4, p {
    color: ${colors.black};
  }

  h3 {
    text-align: center;
    width: 90%;
    margin: 0;

    ::after {
      content: '';
      position: absolute;
      width: 24%;
      height: 5.6rem;
      left: 38%;
      border-bottom: 0.1rem solid ${colors.black};
    }
  }

  h4 {
    margin-top: 2.6rem;
  }

  p {
    padding: 0 7.15rem 4rem;
  }

  header, .intro {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  header {
    justify-content: center;
  }

  .intro {
    justify-content: space-between;
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @media (max-width: 1200px) {

    h3 {
      font-size: 3vw;

      ::after {
        height: 4.15vw;
      }
    }

    p {
      padding: 0 3.35rem 2rem;
    }
  }

  @media (max-width: 800px) {

    h3 {
      font-size: 4.4vw;

      ::after {
        height: 6.1vw;
      }
    }

    p {
      padding: 0 6vw 3.5vw;
    }
  }

  @media (max-width: 400px) {

    h3 {
      font-size: 8.75vw;

      ::after {
        height: 12.2vw;
      }
    }

    p {
      padding: 0 12vw 7vw;
    }
  }
`;

export default NewspaperCardStyle;
