import { GlobalState } from './initialState';
import { Action } from './actions/action';

type State = GlobalState[keyof GlobalState]

const isEqual = <A extends Action>(action: A, state: State): boolean => {
  const keys: (keyof A | State)[] = (Object
    .keys(action)
    .filter((k) => k !== 'type' && k !== 'error') as (keyof A | State)[]);

  return !keys
    .map((key) => action[(key as keyof A)] === state[(key as keyof State)])
    .includes(false);
};

export default isEqual;
