import styled from 'styled-components';

import Button from 'style/Button';

interface Props {
  divider: 1 | 2 | 3;
}

const HomePageStyle = styled.section<Props>`
  margin-bottom: 12.8rem;

  .slider-controls {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    margin-top: -8.4rem;
  }

  .cards-container {
    margin-top: -5.2rem;
  }

  .card-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    flex-wrap: wrap;
  }

  .row {
    width: calc((100% - ${({ divider }): string => `${(divider - 1) * 1.6}rem`}) / (${({ divider }): 1 | 2 | 3 => divider}));
  }

  @media (max-width: 450px) {

    .card-container {
      flex-direction: column;
    }
  }
`;

export const Slide = styled.article`
  max-width: 100%;
  display: flex;
  justify-content: center;

  ${Button} {
    margin-top: 6.4rem;
  }

  div {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    top: 40%;
    width: calc(100% - 20rem);
    max-width: 96rem;

    @media (max-width: 850px) {
      width: calc(100vw - 10rem);
    }

    @media (max-width: 600px) {
      top: 25%;
    }

    @media (max-width: 500px) {
      text-align: center;
      align-items: center;
    }
  }

  h1 {
    display: inline-block;
    position: relative;

    ::before {
      content: '';
      position: absolute;
      width: 20%;
      height: 1px;
      bottom: 0;
      left: 80%;
      border-bottom: 0.4rem solid white;
    }

    @media (max-width: 850px) {
      font-size: 10vw;
    }

    @media (max-width: 500px) {
      font-size: 15vw;

      ::before {
        width: 30%;
        left: 35%;
      }
    }
  }

  img {
    width: 100vw;
    height: 100%;
    position: relative;
    z-index: -1;
    mix-blend-mode: multiply;
  }

  ::before {
    content: '';
    display: block;
    width: 100vw;
    height: 100%;
    background: linear-gradient(
      rgba(51, 51, 51, 0.4),
      rgba(51, 51, 51, 0.3)
    );
    position: absolute;
  }
`;

export default HomePageStyle;
