export const colors = {
  black: 'rgb(51, 51, 51)',
  disabled: 'rgb(204, 204, 204)',
  link: 'rgb(147, 203, 245)',
  error: 'red',
  success: 'green',
};

export const fonts = {
  primary: 'Helvetica',
  secondary: 'Poppins',
};
