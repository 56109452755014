import React, { FunctionComponent } from 'react';
import Card from 'style/Card';
import { useTransition } from 'react-spring';
import { transition as config } from 'animationConfig';
import PopupStyle from './PopupStyle';

interface Props {
  isVisible?: boolean;
}

const Popup: FunctionComponent<Props> = ({ isVisible, children }) => {
  const transitions = useTransition(isVisible, null, {
    config,
    from: { transform: 'scale(0)', opacity: 0 },
    enter: { transform: 'scale(1)', opacity: 1 },
    leave: { transform: 'scale(0)', opacity: 0 },
  });

  return (
    <>
      {transitions.map(({ item, props }) => item && (
      <PopupStyle style={props}>
        <Card height="100%">
          {children}
        </Card>
      </PopupStyle>
      ))}
    </>
  );
};

export default Popup;
