import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { colors } from 'style/variables';

interface ButtonProps {
  dark?: boolean;
  theme: { opacity: number };
}

const Button = styled.button<ButtonProps>`
  width: 6.4rem;
  height: 0.4rem;
  border: none;
  margin: 0.4rem;
  outline: none;
  background-color: ${({ dark }): string => (dark ? colors.black : 'white')};
  opacity: ${({ theme }): number => theme.opacity};
  transition: opacity 0.3s ease;

  :hover {
    opacity: 1;
  }
`;

interface Props {
  dark?: boolean;
  slideId: number;
  activeSlideId: number;
  setIndex(index: number): void;
  deactivateTimer(): void;
}

const buttonTheme = {
  default: {
    opacity: 0.3,
  },
  active: {
    opacity: 1,
  },
};

const SliderButton: FunctionComponent<Props> = ({
  slideId, setIndex, activeSlideId, deactivateTimer, dark,
}) => (
  <Button
    aria-label={`changeToSlide${slideId}`}
    dark={dark}
    theme={
      slideId === activeSlideId ? buttonTheme.active : buttonTheme.default
    }
    onClick={(): void => {
      setIndex(slideId);
      deactivateTimer();
    }}
  />
);

export default SliderButton;
