const debounce = (callback: Function, wait: number, immediate?: boolean): () => void => {
  let timeout: ReturnType<typeof setTimeout>;

  return (): void => {
    const callNow = immediate && !timeout;
    const next = (): void => callback.apply(this);

    clearTimeout(timeout);
    timeout = setTimeout(next, wait);

    if (callNow) {
      next();
    }
  };
};

export default debounce;
