interface Color {
  name: string;
  color: string;
}

const colors: Color[] = [
  {
    name: 'Stone bleach',
    color: '#b8c8d6',
  },
  {
    name: 'Light blue',
    color: '#9cadc4',
  },
  {
    name: 'Grey',
    color: '#9e9e9f',
  },
  {
    name: 'Mid blue',
    color: '#547891',
  },
];

export default colors;
