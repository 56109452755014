import styled from 'styled-components';
import { HeaderMediaQuery } from 'types';

interface Props {
  bgColor: string;
  height: number | string;
  top: number | string;
  topLine: string;
  botLine: string;
  mediaQuery: HeaderMediaQuery;
}

const HeaderStyle = styled.header<Props>`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: ${({ height }): number | string => height};
  clip-path: ${({ topLine, botLine }): string => `polygon(0% 0%, ${topLine} 100% 0%, 100% 100%, ${botLine} 0% 100%)`};

  top: ${({ top }): number | string => top};
  bottom: 0;
  overflow: hidden;
  z-index: 2;
  pointer-events: none;
    
  .container {
    width: 100vw;
    height: 5.6rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 12;
    background-color: ${({ bgColor }): string => bgColor};
    
    pointer-events: all;
    top: 0;

    .logo {
      position: fixed;
      margin: 2rem 0 0 1rem;
      align-self: flex-start;
      z-index: 3;
    }

    .icon {
      position: fixed;
      margin-top: 2rem;
      right: 0.5rem;
      z-index: 3;
    }
  }

  ${({ mediaQuery }): string => {
    const mediaQueries = Object.keys(mediaQuery).map((query) => `@media(max-width: ${query}) {
      clip-path: polygon(0% 0%, ${mediaQuery[query].topLine || ''} 100% 0%, 100% 100%, ${mediaQuery[query].botLine || ''} 0% 100%);
    }`);

    return mediaQueries.join('');
  }}
`;

export default HeaderStyle;
