import React, { useState, useEffect, useRef, FunctionComponent, useCallback, Children } from 'react';
import { useSpring, animated } from 'react-spring';
import { transition as config } from 'animationConfig';

import debounce from 'utils/debounce';
import SliderStyle from './SliderStyle';

interface Props {
  index: number;
  timerActive?: boolean;
  className?: string;
  setIndex?(index: number): void;
}

const Slider: FunctionComponent<Props> = ({
  index, timerActive, className, setIndex, children,
}) => {
  const [slideWidth, setSlideWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const timer = useRef<ReturnType<typeof setInterval> | null>(null);
  const slides = Children.toArray(children);
  const { x } = useSpring({ config, x: -slideWidth * index });

  const goToNextSlide = useCallback(
    (setSlideIndex: (index: number) => void): void => {
      if (index + 1 === slides.length) {
        return setSlideIndex(0);
      } return setSlideIndex(index + 1);
    },
    [index, slides.length],
  );

  const handleResize = debounce(() => {
    if (ref.current) {
      setSlideWidth(ref.current.offsetWidth);
    }
  }, 100, true);

  useEffect(() => {
    window.addEventListener('resize', () => handleResize());
    handleResize();

    return (): void => {
      window.removeEventListener('resize', () => handleResize());
    };
  }, [handleResize]);

  useEffect(() => {
    if (timerActive && setIndex && children) {
      timer.current = setInterval(() => {
        goToNextSlide(setIndex);
      }, 10000);
    }

    return (): void => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [children, goToNextSlide, index, setIndex, slideWidth, timerActive]);

  return (
    <SliderStyle className={className} ref={ref}>
      <animated.div className="container" style={{ transform: x.interpolate((_x) => `translateX(${_x}px)`) }}>
        {children}
      </animated.div>
    </SliderStyle>
  );
};

export default Slider;
