import { Reducer } from 'react';
import { ModalState, initialModalState } from '../initialState';
import { Action } from '../actions/action';
import { isSetModalAction, SetModalAction } from '../actions/modalActions/setModal';
import isEqual from '../isEqual';

const modalReducer: Reducer<ModalState, Action> = (
  state: ModalState = initialModalState, action: Action,
) => {
  if (isSetModalAction(action) && !isEqual<SetModalAction>(action, state)) {
    return {
      id: action.id,
      onClose: action.onClose,
    };
  }

  return state;
};

export default modalReducer;
