import React, { FunctionComponent } from 'react';
import importAll from 'utils/importAll';
import { femaleSizeGuide, maleSizeGuide } from 'assets/data/sizeguide';
import SizeGuideStyle from './SizeGuideStyle';

interface Props {
  gender: 'male' | 'female';
}

const SizeGuide: FunctionComponent<Props> = ({ gender }) => {
  const guide = gender === 'male' ? maleSizeGuide : femaleSizeGuide;
  const guideImage = importAll(require.context('../../../../assets/svg', false, /\.svg$/), 'sizeguide.svg')[0];

  return (
    <SizeGuideStyle>
      <h3>Size guide</h3>
      <div className="container">
        <div className="image-container">
          <img src={guideImage} alt="sizeguide" width="192" height="182" />
          <p className="caution">Measure on flat-lying jeans!</p>
        </div>
        <table>
          <tr>
            <th>Size</th>
            <th>Waist</th>
            <th>Hip</th>
          </tr>
          {guide.map(({ size, waist, hip }) => (
            <tr key={size}>
              <td>{size}</td>
              <td>{`${waist} cm`}</td>
              <td>{`${hip} cm`}</td>
            </tr>
          ))}
        </table>
      </div>
    </SizeGuideStyle>
  );
};

export default SizeGuide;
