import React, { useEffect, FunctionComponent, useCallback } from 'react';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useSpring } from 'react-spring';
import { hover as config } from 'animationConfig';
import { moveIndicator as moveIndicatorAction } from 'hooks/globalState/actions/navigationActions/moveIndicator';
import NavBarLink from 'components/navigation/navBarLink/NavBarLink';
import debounce from 'utils/debounce';
import { Link } from 'components/navigation/header/Header';
import useGlobalState from 'hooks/globalState/useGlobalState';
import useDispatch from 'hooks/globalState/useDispatch';
import NavBarStyle, { PageIndicator } from './NavBarStyle';

interface Props extends RouteComponentProps {
  links: Link[];
}

const NavBar: FunctionComponent<Props> = ({ location, links }) => {
  const { indicatorPos } = useGlobalState('navigation');
  const moveIndicator = useDispatch(moveIndicatorAction);
  const spring = useSpring({ config, width: indicatorPos.width, left: indicatorPos.left });

  const moveIndicatorToElement = useCallback((element?: Element): void => {
    if (element) {
      const currentElement = element.getBoundingClientRect();
      moveIndicator({ indicatorPos: {
        width: currentElement.width,
        left: currentElement.left,
      } });
    }
  }, [moveIndicator]);

  useEffect(() => {
    const [activeLink] = document.getElementsByClassName('active-link');
    moveIndicatorToElement(activeLink);

    const onResize = debounce(() => {
      moveIndicatorToElement(activeLink);
    }, 100, true);

    window.addEventListener('resize', onResize);

    return (): void => {
      window.removeEventListener('resize', onResize);
    };
  }, [location.pathname, moveIndicatorToElement]);

  return (
    <NavBarStyle>
      <PageIndicator style={spring} />
      <ul>
        {links.map((link) => (
          <NavBarLink
            key={link.page}
            link={link}
            active={location.pathname.split('/')[1] === link.path}
            moveIndicatorToElement={moveIndicatorToElement}
          />
        ))}
      </ul>
    </NavBarStyle>
  );
};

export default withRouter(NavBar);
