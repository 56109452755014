import styled from 'styled-components';
import { colors } from 'style/variables';
import Button from 'style/Button';
import Color from 'style/Color';
import ProductInfoStyle from 'components/webshop/productInfo/ProductInfoStyle';

const CustomizationFormStyle = styled.div`
  width: 29rem;

  ${ProductInfoStyle} {
    margin-top: 1.6rem;

    button {
      align-self: flex-start;
      padding: 0;
    }

    .colors, .sizes {
      display: flex;
      flex-wrap: wrap;
    }
  }

  ${Color}:first-child {
    margin-left: 0;
  }

  ${Button} {
    display: block;
    margin-top: 4rem;
  }

  h2, h3, h4 {
    color: ${colors.black};
  }

  h2 {
    display: inline-block;
    position: relative;
    margin-left: -.4rem;

    ::before {
      content: '';
      position: absolute;
      width: 40%;
      height: 1px;
      bottom: 0;
      left: 0;
      border-bottom: 0.4rem solid ${colors.black};
      margin-left: .4rem;
    }
  }

  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 1.3rem;
  }

  .price {
    display: flex;

    .currency {
      font-weight: lighter;
      color: ${colors.black};
      margin: .4rem .8rem 0 0;
    }
  }
`;

export default CustomizationFormStyle;
