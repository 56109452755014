import styled from 'styled-components';

const SliderStyle = styled.div.attrs({ 'data-testid': 'slider' })`
  position: relative;
  overflow-x: hidden;

  .container {
    width: 100%;
    height: 100%;
    display: flex;
  }
`;

export default SliderStyle;
