import React, { useReducer, FunctionComponent } from 'react';

import updateObject from 'utils/updateObject';
import { stateContext } from './useGlobalState';
import { GlobalState, initialState as initialStateValue } from './initialState';
import rootReducer from './reducers/root';
import { DispatchContext } from './useDispatch';

interface Props {
  state?: { [key in keyof GlobalState]?: Partial<GlobalState[key]> };
}

const GlobalStateProvider: FunctionComponent<Props> = ({ state, children }) => {
  const initialState = updateObject(initialStateValue, state);
  const [globalState, dispatch] = useReducer(rootReducer, initialState);

  const renderProvider = (keys: (keyof GlobalState)[]): JSX.Element => {
    const StateContext = stateContext[keys[0]];
    const newKeys = [...keys];
    newKeys.shift();

    return (
      <StateContext.Provider value={globalState[keys[0]]}>
        {newKeys.length
          ? renderProvider(newKeys)
          : children
        }
      </StateContext.Provider>
    );
  };

  return (
    <DispatchContext.Provider value={dispatch}>
      {renderProvider((Object.keys(initialState) as (keyof GlobalState)[]))}
    </DispatchContext.Provider>
  );
};

export default GlobalStateProvider;
