/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent, useRef } from 'react';
import { CardExpiryElement, CardCvcElement, CardNumberElement, useElements } from '@stripe/react-stripe-js';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import camelCase from 'utils/camelCase';
import { colors } from 'style/variables';
import StripeInputStyle from './StripeInputStyle';

interface Props {
  id: 'card-number' | 'card-expiry' | 'card-cvc';
  label: string;
  setIsValid(valid: boolean): void;
}

const StripeInput: FunctionComponent<Props> = ({ id, label, setIsValid }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const elements = useElements();

  const addClassName = (className?: 'focus' | 'error' | 'success'): void => {
    if (containerRef.current) {
      containerRef.current.classList.remove('focus', 'error', 'success');
      if (className) {
        containerRef.current.classList.add(className);
      }
    }
  };

  const onBlur = (status?: StripeElementChangeEvent): void => {
    if (labelRef.current) {
      labelRef.current.innerText = label;

      if (status && status.error) {
        labelRef.current.innerText = status.error.message;
        addClassName('error');
      } else if (status && status.complete) {
        addClassName('success');
      } else {
        addClassName();
      }
    }
  };

  const onChange = (e: StripeElementChangeEvent): void => {
    setIsValid(e.complete);
    const element = elements?.getElement(camelCase(id) as any);
    if (element) {
      element.on('blur', () => onBlur(e));
    }
  };

  const renderElement = (): JSX.Element | null => {
    const parameters = {
      id,
      onFocus: (): void => addClassName('focus'),
      onBlur,
      onChange,
      options: {
        style: {
          base: {
            '::placeholder': {
              color: colors.disabled,
            },
          },
        },
      },
    };

    switch (id) {
      case 'card-number':
        return <CardNumberElement {...parameters} />;
      case 'card-expiry':
        return <CardExpiryElement {...parameters} />;
      case 'card-cvc':
        return <CardCvcElement {...parameters} />;
      default: return null;
    }
  };

  return (
    <StripeInputStyle>
      <div ref={containerRef}>
        <label ref={labelRef} htmlFor={id}>{label}</label>
        {renderElement()}
      </div>
    </StripeInputStyle>
  );
};

export default StripeInput;
