import React, { FunctionComponent, useState, useEffect } from 'react';
import debounce from 'utils/debounce';
import Popup from 'components/popup/Popup';
import Link from 'components/navigation/Link';
import PrivacyPolicy from 'assets/pdf/Privacy_Policy.pdf';
import Button, { SecondaryButton } from 'style/Button';
import Image from 'components/Image';
import Tracker from 'lib/tracker';
import getCookie from 'utils/getCookie';
import ConsentBoxStyle from './ConsentBoxStyle';

const ConsentBox: FunctionComponent = () => {
  const isAccepted = getCookie('accept-cookies');
  const [isVisible, setIsVisible] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);

  if (isAccepted) {
    Tracker.initialize(
      process.env.GOOGLE_ANALYTICS_KEY as string,
      process.env.HOTJAR_KEY as string,
      process.env.HOTJAR_SNIPPET_VERSION as string,
    );
  }

  const onScroll = debounce(() => {
    if (!isDeclined) setIsVisible(true);
  }, 100);

  const onAcceptClick = (): void => {
    const expiryDate = new Date(new Date().setFullYear(new Date().getFullYear() + 3));
    document.cookie = `accept-cookies=true; expires=${expiryDate}; path=/`;
    setIsVisible(false);
  };

  const onDeclineClick = (): void => {
    setIsDeclined(true);
    setIsVisible(false);
  };

  useEffect(() => {
    if (!isAccepted) {
      document.addEventListener('scroll', onScroll);
    }

    return (): void => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isAccepted, onScroll]);

  return (
    <Popup isVisible={isVisible}>
      <ConsentBoxStyle>
        <h4>Hi there!</h4>
        <h3>We are cookies</h3>
        <Image src="cookies.jpg" alt="cookies" className="cookie-icon" width={111} height={95} />
        <p>
          Our job is to count the number of visits to this site.&nbsp;
          Our work greatly helps the people at Tirette,&nbsp;
          because we allow them to identify many areas where&nbsp;
          they can improve the experience for you.&nbsp;
          All while respecting your&nbsp;
          <Link to={PrivacyPolicy} className="link">privacy</Link>
          .
        </p>
        <div className="actions">
          <Button dark to="#" onClick={onAcceptClick}>I accept</Button>
          <SecondaryButton dark to="#" onClick={onDeclineClick}>I don&apos;t accept</SecondaryButton>
        </div>
      </ConsentBoxStyle>
    </Popup>
  );
};

export default ConsentBox;
