const updateObject = <O extends { [key: string]: any }>(
  initial: O,
  newObject?: { [key in keyof O]?: Partial<O[key]> },
): O => {
  if (!newObject) {
    return initial;
  }

  const keys = Object.keys(newObject);
  let result = {};

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];

    if (typeof initial[key] === 'object') {
      result = { ...initial, ...result, [key]: { ...updateObject(initial[key], newObject[key]) } };
    } else {
      result = { ...initial, ...result, ...newObject };
    }
  }

  return result as O;
};

export default updateObject;
