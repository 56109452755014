import styled from 'styled-components';

const SocialNavStyle = styled.nav`
  position: fixed;
  height: 100vh;
  width: 3.5rem;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    display: block;
    height: 6rem;
  }
`;

export default SocialNavStyle;
