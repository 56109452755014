import React, { PureComponent, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ErrorPage from './pages/errorPage/ErrorPage';

type State = {
  error: Error | null;
}

class ErrorBoundary extends PureComponent<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = { error: null };
    this.resetError = this.resetError.bind(this);
  }

  componentDidUpdate(prevProps: RouteComponentProps): void {
    const { location } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      this.resetError();
    }
  }

  componentDidCatch(error: Error): void {
    this.setState({ error });
  }

  resetError(): void {
    this.setState({ error: null });
  }

  render(): JSX.Element | ReactNode {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return <ErrorPage resetError={this.resetError} />;
    } return children;
  }
}

export default withRouter(ErrorBoundary);
