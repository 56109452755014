import styled from 'styled-components';
import { colors } from 'style/variables';

const ModalContentStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3, p {
    color: ${colors.black};
    text-align: center;
    max-width: 50%;
  }

  h3 {
    margin-bottom: 6.4rem;
  }

  @media (max-width: 600px) {
    h3, p {
      max-width: 90%;
    }
  }
`;

export default ModalContentStyle;
