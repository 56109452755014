import styled from 'styled-components';

import Card from 'style/Card';

const FeatureCardStyle = styled.div`

  ${Card} {
    height: 20.4rem;
  }

  h3 {
    text-align: center;
    margin-top: 1.6rem;

    ::after {
      content: '';
      position: absolute;
      width: 7%;
      height: 4.2rem;
      left: 46.5%;
      border-bottom: 0.1rem solid white;
    }
  }

  h4 {
    margin-top: 0.8rem;
    text-align: center;
  }

  .icon-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -1.6rem;
  }

  ${Card} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  @media (max-width: 650px) {

    .icon {
      width: 100px;
    }
  }

  @media (max-width: 450px) {

    ${Card} {
      height: 40rem;
    }

    .icon-container {
      flex-wrap: wrap;
    }

    .icon {
      width: 30%;
      margin: 0 10%;
    }
  }
`;

export default FeatureCardStyle;
