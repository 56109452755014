interface Feature {
  title: string;
  image: string;
}

interface Color {
  color: string;
  image: string;
}

interface FeatureCard {
  image: string;
  title: string;
  features: Feature[];
}

interface ProductCard {
  title: string;
  subtitle: string;
  cta: string;
  url: string;
  colors: Color[];
}

interface NewsCard {
  image: string;
  title: string;
  subtitle: string;
  cta: string;
  url: string;
  height?: number;
  intro?: string;
  gradient?: string;
}

interface Cards {
  feature: FeatureCard[];
  product: ProductCard[];
  news: NewsCard[];
}

const cards: Cards = {
  feature: [
    {
      image: 'feature_card_bg.jpg',
      title: 'Features',
      features: [
        {
          title: 'Pickpocket proof',
          image: 'lock_icon.svg',
        },
        {
          title: 'Supersized pockets',
          image: 'pocket_icon.svg',
        },
        {
          title: 'Extreme comfort',
          image: 'cushion_icon.svg',
        },
      ],
    },
  ],
  product: [
    {
      title: 'Havana',
      subtitle: 'New Arrivals',
      cta: 'Shop now',
      url: '/collection/women',
      colors: [
        {
          color: '#b8c8d6',
          image: 'havana_bleached_product_card.jpg',
        },
        {
          color: '#9cadc4',
          image: 'havana_blue_product_card.jpg',
        },
      ],
    },
    {
      title: 'Brooklyn',
      subtitle: 'New Arrivals',
      cta: 'Shop now',
      url: '/collection/men',
      colors: [
        {
          color: '#9e9e9f',
          image: 'brooklyn_grey_product_card.jpg',
        },
        {
          color: '#547891',
          image: 'brooklyn_blue_product_card.jpg',
        },
      ],
    },
  ],
  news: [],
};

export default cards;
