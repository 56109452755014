import ActionTypes from '../types';
import { Action } from '../action';

interface ResizeActionOptions {
  clientWidth: number;
  clientHeight: number;
}

export interface ResizeAction extends Action {
  clientWidth: number;
  clientHeight: number;
}

export const resize = (data: ResizeActionOptions): ResizeAction => ({
  type: ActionTypes.Resize,
  clientWidth: data.clientWidth,
  clientHeight: data.clientHeight,
});

export const isResizeAction = (action: Action): action is ResizeAction => (
  action.type === ActionTypes.Resize
);
