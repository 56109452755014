import React, { FunctionComponent } from 'react';

import Card from 'style/Card';
import importAll from 'utils/importAll';
import FeatureCardStyle from './FeatureCardStyle';

interface Feature {
  title: string;
  image: string;
}

interface Card {
  image: string;
  title: string;
  features: Feature[];
}

interface Props {
  card: Card;
}

const FeatureCard: FunctionComponent<Props> = ({ card }) => {
  const icons: string[] = [];

  card.features.forEach((feature) => icons.push(importAll(require.context('../../../assets/svg/', false, /\.svg$/), feature.image)[0]));

  return (
    <FeatureCardStyle>
      <Card image={card.image}>
        <h3>{card.title}</h3>
        <div className="icon-container">
          {card.features.map((feature, i) => (
            <div className="icon" key={feature.title}>
              <img src={icons[i]} alt={feature.title} />
              <h4>{feature.title}</h4>
            </div>
          ))}
        </div>
      </Card>
    </FeatureCardStyle>
  );
};

export default FeatureCard;
