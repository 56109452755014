import React, { FunctionComponent } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import H from 'history';

interface Props<S = H.LocationState> extends LinkProps<S> {
  to: string;
}

const Link: FunctionComponent<Props> = ({ to, children, ...rest }) => {
  const parseTo = (_to: string): HTMLAnchorElement => {
    const parser = document.createElement('a');
    parser.href = _to;
    return parser;
  };

  const toException = to === '#' ? to : undefined;
  const toLocation = parseTo(to);
  const isInternal = window.location.host === toLocation.host;
  const isPdf = new RegExp('.+.pdf$').test(to);

  return isInternal && !isPdf
    ? <RouterLink to={toException || toLocation.pathname} {...rest}>{children}</RouterLink>
    : <a href={to} rel="noopener noreferrer" target="_blank" {...rest}>{children}</a>;
};

export default Link;
