import styled from 'styled-components';
import { Theme } from 'types';

interface Props {
  theme: Theme;
}

interface HamburgerDropdownProps {
  isOpen: boolean;
}

const HamburgerStyle = styled.nav<Props>`
  position: fixed;
  top: 2rem;
  right: 1rem;

  input {
    display: block;
    width: 4rem;
    height: 3.2rem;
    position: absolute;
    top: -0.7rem;
    left: -0.5rem;
    cursor: pointer;
    opacity: 0;
    z-index: 3;

    :checked ~ span {
      background-color: white;
      opacity: 1;
      transform: rotate(45deg) translate(0.1rem, -0.1rem);

      & ~ span {
        background-color: white;
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);

        & ~ span {
          background-color: white;
          opacity: 1;
          transform: rotate(-45deg) translate(0, -0.1rem);
        }
      }
    }

    :checked ~ .hamburger-navigation {
      transform: scale(1, 1);
      opacity: 1;
      pointer-events: auto;
    }
  }

  span {
    display: block;
    width: 3.3rem;
    height: 0.4rem;
    margin-bottom: 0.5rem;
    z-index: 2;
    position: relative;
    background-color: ${({ theme }): string => theme.color};
    border-radius: 0.3rem;
    transform-origin: 0.4rem 0;
    will-change: transform, opacity, background-color;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, background-color 0.5s ease;

    & + & {
      transform-origin: 0% 0%;
    }

    & + & + & {
      transform-origin: 0% 100%;
    }
  }

  li {
    padding: 1rem 0;
  }

  a, button {
    text-transform: uppercase;
    line-height: 2.3rem;
    color: white;
    display: flex;
    padding: 0;

    ::after {
      content: attr(data-number);
      display: block;
      width: 3rem;
      color: #8a8a8a;
      text-align: right;
    }
  }

  .hamburger-navigation {
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin: -4.7rem 0 0 calc(-100vw + 4.3rem);
    padding: 12.5rem 5rem 5rem;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    transform: scale(2, 1);
    opacity: 0;
    pointer-events: none;
    will-change: transform, opacity;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
`;

export const HamburgerDropdown = styled.ul<HamburgerDropdownProps>`
  position: absolute;
  margin-top: -1rem;
  right: ${({ isOpen }): string => (isOpen ? 'calc(15rem + 10vw)' : 'calc(14rem + 10vw)')};
  opacity: ${({ isOpen }): number => (isOpen ? 1 : 0)};
  will-change: right, opacity;
  transition: right 0.5s, opacity 0.3s;
`;

export default HamburgerStyle;
