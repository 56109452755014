import { createContext, Dispatch, useContext, useCallback } from 'react';
import { Action } from './actions/action';

export type ActionCb<D, A extends Action> = (data: D) => A;
export type DispatchCb<D> = (data: D) => void;

export const DispatchContext = createContext((() => 0) as Dispatch<Action>);

const useDispatch = <D, A extends Action>(action: ActionCb<D, A>): DispatchCb<D> => {
  const dispatch = useContext(DispatchContext);

  return useCallback((data: D) => dispatch(action(data)), [action, dispatch]);
};

export default useDispatch;
