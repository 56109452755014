/* eslint-disable no-underscore-dangle */
import tracker from 'react-ga';

declare global {
  interface Window {
    hj: any;
    _hjSettings: {
      hjid: string | number;
      hjsv: string | number;
    };
    _scriptPath: string;
  }
}

const initializeHotjar = (id: string | number, sv: string | number): void => {
  const url = '//static.hotjar.com/c/hotjar-';
  const ext = '.js?sv=';
  let head: HTMLHeadElement;
  let script: HTMLScriptElement;

  const hotjar = (): void => {
    window.hj.q = window.hj.q || [];
    (window.hj.q).push(window, document, url, ext, head, script);
  };

  window.hj = window.hj || hotjar;
  window._hjSettings = { hjid: id, hjsv: sv };
  window._scriptPath = `${url}${id}${ext}${sv}`;

  if (!document.querySelector(`script[src*="${window._scriptPath}"]`)) {
    [head] = document.getElementsByTagName('head');
    script = document.createElement('script');
    script.async = true;
    script.src = window._scriptPath;
    head.appendChild(script);
  }
};

type Initialize = (
  googleAnalyticsKey?: string,
  hotjarKey?: string | number,
  hotjarSnippetVersion?: string | number,
) => void;

const initialize: Initialize = (googleAnalyticsKey, hotjarKey, hotjarSnippetVersion) => {
  if (googleAnalyticsKey) {
    tracker.initialize(googleAnalyticsKey, { testMode: process.env.NODE_ENV === 'test' });
  }

  if (hotjarKey && hotjarSnippetVersion) {
    initializeHotjar(hotjarKey, hotjarSnippetVersion);
  }
};

const event = (category: string, action: string, label?: string): void => {
  tracker.event({ category, action, label });
};

export default {
  ...tracker,
  ...tracker.testModeAPI,
  initialize,
  event,
};
