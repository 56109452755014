import styled from 'styled-components';
import { colors } from 'style/variables';

interface Props {
  disabled?: boolean;
  dark?: boolean;
  active?: boolean;
  color?: string;
}

const getBorder = (disabled?: boolean, dark?: boolean): string => {
  switch (true) {
    case disabled: return colors.disabled;
    case dark: return colors.black;
    default: return 'white';
  }
};

const Color = styled.div.attrs(({ color }) => ({ 'data-testid': color }))<Props>`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 1.6rem;
  border: ${({ disabled, dark }: Props): string => `0.2rem solid ${getBorder(disabled, dark)}`};
  margin: 0 0.8rem 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: color;
  transition: color .2s ease;

  ::after {
    content: '';
    display: block;
    position: absolute;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    background-color: ${({ disabled, color }): string => (disabled && color ? 'rgba(255, 255, 255, 0)' : `${color}`)};
  }

  ::before {
    content: '';
    display: block;
    width: ${({ active }: Props): '0.4rem' | 0 => (active ? '0.4rem' : 0)};
    height: ${({ active }: Props): '0.4rem' | 0 => (active ? '0.4rem' : 0)};
    border-radius: 0.2rem;
    background-color: ${({ dark }: Props): string => (dark ? colors.black : 'white')};
    will-change: width, height;
    z-index: 1;
    transition: width 0.2s, height 0.2s;
  }

  :hover::before {
    width: ${({ disabled }: Props): '0rem' | '0.4rem' => (disabled ? '0rem' : '0.4rem')};
    height: ${({ disabled }: Props): '0rem' | '0.4rem' => (disabled ? '0rem' : '0.4rem')};
    cursor: ${({ disabled }: Props): 'default' | 'pointer' => (disabled ? 'default' : 'pointer')};
  }
`;

export default Color;
