const importAll = (
  context: __WebpackModuleApi.RequireContext, fileName: string, originalFileNames?: true,
): string[] => {
  const files = context
    .keys()
    .filter((word) => word.split('./')[1].includes(fileName));

  return originalFileNames
    ? files
    : files.map(context) as string[];
};

export default importAll;
