import React, { FunctionComponent, useEffect, useState } from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import links from 'assets/data/links';
import Link from 'components/navigation/Link';
import { setModal } from 'hooks/globalState/actions/modalActions/setModal';
import useDispatch from 'hooks/globalState/useDispatch';
import FooterStyle from './FooterStyle';

const Footer: FunctionComponent<RouteComponentProps> = ({ location }) => {
  const openModal = useDispatch(setModal);
  const [heights, setHeights] = useState<number[]>([]);

  useEffect(() => {
    const elements = Array.from(document.getElementsByClassName('title'));
    const newHeights: number[] = elements.map((element) => element.getBoundingClientRect().height);

    setHeights(newHeights);
  }, []);

  return (
    <FooterStyle>
      {links.footer.map((link, index) => (
        <div
          key={link.title}
          className="link-list"
          style={{ height: heights[index] }}
        >
          <p className="title">{link.title}</p>
          <ul>
            {link.links.map(({ name, url, modal }) => (
              <li key={name}>
                <Link
                  to={`${url || location.pathname}`}
                  onClick={(): void => openModal({ id: modal || undefined })}
                >
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </FooterStyle>
  );
};

export default withRouter(Footer);
