import ActionTypes from '../types';
import { Action } from '../action';

interface ActivateDropdownOptions {
  activeDropdownId?: string;
}

export interface ActivateDropdownAction extends Action {
  activeDropdownId?: string;
}

export const activateDropdown = (data: ActivateDropdownOptions): ActivateDropdownAction => ({
  type: ActionTypes.ActivateDropdown,
  activeDropdownId: data.activeDropdownId,
});

export const isActivateDropdownAction = (action: Action): action is ActivateDropdownAction => (
  action.type === ActionTypes.ActivateDropdown
);
