import styled from 'styled-components';
import { colors } from 'style/variables';

interface Props {
  error: boolean;
}

const CheckboxStyle = styled.label<Props>`
  display: block;
  margin-top: 2rem;
  position: relative;
  color: ${colors.black};
  font-size: 1.4rem;
  cursor: pointer;
  padding-left: 2.4rem;

  :hover input ~ .checkmark::before {
    width: .4rem;
    height: .4rem;
  }

  input:checked ~ .checkmark {
    ::before, ::after {
      position: absolute;
      height: .8rem;
      width: .2rem;
    }

    ::before {
      transform: rotate(45deg);
    }

    ::after {
      transform: rotate(-45deg);
    }
  }
  
  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: .3rem;
    left: 0;
    height: 1.6rem;
    width: 1.6rem;
    border: ${({ error }): string => (error ? `.2rem solid ${colors.error}` : `.2rem solid ${colors.black}`)};
    border-radius: .4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: text-top;

    ::before, ::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-radius: .2rem;
      background-color: ${({ error }): string => (error ? colors.error : colors.black)};
      will-change: width, height;
      z-index: 1;
      transition: width .2s, height .2s;
    }
  }

  .error {
    position: absolute;
    font-size: 1.2rem;
    color: ${colors.error};
    top: -3.4rem;
    left: 0;
  }
`;

export default CheckboxStyle;
