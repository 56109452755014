import React, { useState, useEffect, useRef, FunctionComponent } from 'react';

import Button, { SecondaryButton } from 'style/Button';
import Card from 'style/Card';
import debounce from 'utils/debounce';
import NewsCardStyle from './NewsCardStyle';

interface Card {
  image: string;
  title: string;
  subtitle: string;
  cta: string;
  url: string;
  intro?: string | null;
  gradient?: string;
  height?: number;
}

interface Props {
  card: Card;
}

const NewsCard: FunctionComponent<Props> = ({ card }) => {
  const [height, setHeight] = useState(0);
  const cardRef = useRef<HTMLDivElement>(null);
  const introRef = useRef<HTMLDivElement>(null);

  const calcHeight = (width: number): number => {
    if (card.height) {
      return card.height * width;
    } if (card.intro) {
      return 1.45 * width;
    } return width;
  };

  const wrapText = (container: HTMLDivElement): void => {
    const intro = container.querySelector('p');
    const containerHeight = container.clientHeight;

    if (intro) {
      intro.textContent = card.intro as string | null;

      while (intro.offsetHeight > (containerHeight * 0.90)) {
        if (intro.textContent) {
          intro.textContent = intro.textContent.replace(/\W*\s(\S)*$/, '...');
        }
      }
    }
  };

  const onResize = debounce(() => {
    if (cardRef.current) {
      setHeight(calcHeight(cardRef.current.clientWidth));
    }

    if (introRef.current) {
      wrapText(introRef.current);
    }
  }, 100, true);

  useEffect(
    () => {
      window.addEventListener('resize', onResize);
      onResize();

      return (): void => {
        window.removeEventListener('resize', onResize);
      };
    },
    [onResize],
  );

  useEffect(
    () => {
      onResize();
    },
    [cardRef, onResize, height],
  );

  return (
    <NewsCardStyle>
      <Card
        height={height}
        ref={cardRef}
        image={card.image}
        background={card.gradient ? `linear-gradient(transparent, ${card.gradient}), ` : ''}
      >
        <div className="header">
          <h2>{card.title}</h2>
          <h4>{card.subtitle}</h4>
          {!card.intro && (
            <Button to={card.url}>{card.cta}</Button>
          )}
        </div>
        {card.intro && (
          <div className="intro" ref={introRef}>
            <p>{card.intro}</p>
            <SecondaryButton forward to={card.url}>{card.cta}</SecondaryButton>
          </div>
        )}
      </Card>
    </NewsCardStyle>
  );
};

export default NewsCard;
