import ActionTypes from '../types';
import { Action } from '../action';

interface ToggleHamburgerActionOptions {
  isHamburgerOpen: boolean;
}

export interface ToggleHamburgerAction extends Action {
  isHamburgerOpen: boolean;
}

export const toggleHamburger = (data: ToggleHamburgerActionOptions): ToggleHamburgerAction => ({
  type: ActionTypes.ToggleHamburger,
  isHamburgerOpen: data.isHamburgerOpen,
});

export const isToggleHamburgerAction = (action: Action): action is ToggleHamburgerAction => (
  action.type === ActionTypes.ToggleHamburger
);
