import React, { FunctionComponent } from 'react';
import Wrapper from 'style/Wrapper';
import AboveTheFold from 'style/AboveTheFold';
import BackgroundImage from 'style/BackgroundImage';
import ConceptPageStyle from './ConceptPageStyle';
import ConceptPageNewspapers from './conceptPageNewspapers/ConceptPageNewspapers';
import ConceptPageDemo from './conceptPageDemo/ConceptPageDemo';
import ConceptPageCards from './conceptPageCards/ConceptPageCards';

const ConceptPage: FunctionComponent = () => (
  <ConceptPageStyle>
    <AboveTheFold>
      <BackgroundImage image="placeholder_card4.jpg">
        <h1>Pickpocket proof jeans</h1>
      </BackgroundImage>
    </AboveTheFold>
    <Wrapper>
      <ConceptPageCards />
      <ConceptPageNewspapers />
    </Wrapper>
    <ConceptPageDemo />
  </ConceptPageStyle>
);

export default ConceptPage;
