import React, { FunctionComponent } from 'react';
import NewspaperCard from 'components/cards/newspaperCard/NewspaperCard';
import ConceptPageNewspapersStyle from './ConceptPageNewspapersStyle';

const ConceptPageNewspapers: FunctionComponent = () => (
  <ConceptPageNewspapersStyle>
    <h3>Rightfully so, you&apos;re not alone!</h3>
    <div className="container">
      <NewspaperCard
        title="Pickpocketing surge in Paris"
        subtitle="The Local"
        body="Reports of pickpocketing on the Paris Metro have shot up 74 percent in the last year, new figures show. It's an old problem that is reaching new level. New figures show that twice as many thefts were reported on the Paris Metro in 2019 compared to last year. According to the city's transport operator Ile-de-France-Mobilites (IDFM), ..."
        url="https://www.thelocal.fr/20191115/reports-of-pickpocketing-on-paris-metro-double-in-a-year"
      />
      <NewspaperCard
        title="Pickpocketing is on the Rise"
        subtitle="The Atlantic"
        body='Why pickpockets love our digitally distracted age. Sherman "O. T." Powell, a retired pickpocket who honed his skills on the streets of New York in the 1980s, claims that at the height of his career he could clear up to $5.000 a month (about $12,000 today, adjusting for inflation). But over the years, his income began to fall.'
        url="https://www.theatlantic.com/magazine/archive/2019/05/pickpocket-smartphones/585997/"
      />
      <NewspaperCard
        title="Man caught stealing over 100 phones"
        subtitle="The Verge"
        body="Coachella might be a Snapchatter's paradise, but it's also, apparently, a phone thief's paradise. Thirty-six-year-old Reinaldo De Jesus Henao was arrested at the festival on Sunday with more than 100 stolen smartphones in his backpack, KMIR News reports."
        url="https://www.theverge.com/2017/4/17/15327670/coachella-phone-thief-find-my-iphone"
      />
    </div>
  </ConceptPageNewspapersStyle>
);

export default ConceptPageNewspapers;
