import { createContext, useContext } from 'react';
import { GlobalState, initialState } from './initialState';

export const stateContext = Object
  .keys(initialState)
  .map((key: keyof GlobalState) => ({
    [key]: createContext(initialState[key]),
  }))
  .reduce((obj, item) => ({ ...obj, ...item }));

const useGlobalState = <K extends keyof GlobalState>(property: K): GlobalState[K] => (
  (useContext(stateContext[property]) as GlobalState[K])
);

export default useGlobalState;
