import styled from 'styled-components';
import BackgroundImage from 'style/BackgroundImage';

const ErrorPageStyle = styled(BackgroundImage)`
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
    margin: 0 1.6rem;
  }

  p {
    margin: 6.4rem 1.6rem 2.4rem;
    max-width: 30rem;
    text-align: center;
  }
`;

export default ErrorPageStyle;
