import React, { FunctionComponent, DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import getImageSizes from 'utils/getImageSizes';

type ImageProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

const Image: FunctionComponent<ImageProps> = ({ src, alt, className, ...rest }) => {
  const images = getImageSizes(src || '');
  const sizes = Object.keys(images).map((size) => parseInt(size, 10));
  const largestSize = Math.max(...sizes);

  return (
    <picture className={className} {...rest}>
      {sizes.map((size) => (
        <source key={size} media={`(max-width: ${size}px)`} srcSet={images[size]} className={className} />
      ))}
      <img src={images[largestSize]} alt={alt} className={className} {...rest} />
    </picture>
  );
};

export default Image;
