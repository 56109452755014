import { useCallback, useEffect, useRef, RefObject } from 'react';

const useOnClose = <E extends HTMLElement>(callback: () => void): RefObject<E> => {
  const parent = useRef<E>(null);

  const onEsc = useCallback((e: KeyboardEvent): void => {
    if (e.key === 'Escape') callback();
  }, [callback]);

  const onOutsideClick = useCallback((e: MouseEvent): void => {
    if (e.target === parent.current) callback();
  }, [callback]);

  useEffect(() => {
    if (parent.current) {
      parent.current.onclick = onOutsideClick;
    }
  });

  useEffect(() => {
    document.addEventListener('keydown', onEsc);

    return ((): void => {
      document.removeEventListener('keydown', onEsc);
    });
  }, [onEsc, onOutsideClick]);

  return parent;
};

export default useOnClose;
