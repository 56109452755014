interface Slide {
  id: number;
  image: string;
  title: string;
  subtitle: string;
  cta: string;
  url: string;
  gradient: string;
}

const slides: { [page: string]: Slide[] } = {
  home: [
    {
      id: 1,
      image: 'havana.jpg',
      title: 'Meet Havana',
      subtitle: 'Freedom where you least expect it',
      cta: 'Go to shop',
      url: '/collection/women',
      gradient: 'rgba(221, 189, 180, .5)',
    },
    {
      id: 2,
      image: 'brooklyn.jpg',
      title: 'Meet Brooklyn',
      subtitle: 'Freedom where you least expect it',
      cta: 'Go to shop',
      url: '/collection/men',
      gradient: 'rgba(107, 143, 174, .5)',
    },
  ],
  havana: [
    {
      id: 1,
      image: 'placeholder_card5.jpg',
      title: 'Havana',
      subtitle: 'Dare to take control',
      cta: 'Add to cart',
      url: '#',
      gradient: 'rgba(107, 143, 174, .5)',
    },
    {
      id: 2,
      image: 'placeholder_card1.jpg',
      title: 'Havana',
      subtitle: 'Dare to take control',
      cta: 'Add to cart',
      url: '#',
      gradient: 'rgba(221, 189, 180, .5)',
    },
    {
      id: 3,
      image: 'placeholder_card6.jpg',
      title: 'Havana',
      subtitle: 'Dare to take control',
      cta: 'Add to cart',
      url: '#',
      gradient: 'rgba(107, 143, 174, .5)',
    },
  ],
  brooklyn: [
    {
      id: 1,
      image: 'placeholder_card2.jpg',
      title: 'Brooklyn',
      subtitle: 'Dare to take control',
      cta: 'Add to cart',
      url: '#',
      gradient: 'rgba(107, 143, 174, .5)',
    },
    {
      id: 2,
      image: 'placeholder_card4.jpg',
      title: 'Brooklyn',
      subtitle: 'Dare to take control',
      cta: 'Add to cart',
      url: '#',
      gradient: 'rgba(107, 143, 174, .5)',
    },
  ],
};

export default slides;
