import { Reducer } from 'react';
import isEqual from '../isEqual';
import { Action } from '../actions/action';
import { NavigationState, initialNavigationState } from '../initialState';
import { isToggleHamburgerAction, ToggleHamburgerAction } from '../actions/navigationActions/toggleHamburger';
import { isActivateDropdownAction, ActivateDropdownAction } from '../actions/navigationActions/activateDropdown';
import { isMoveIndicatorAction, MoveIndicatorAction } from '../actions/navigationActions/moveIndicator';

const navigationReducer: Reducer<NavigationState, Action> = (
  state: NavigationState = initialNavigationState, action: Action,
) => {
  if (isToggleHamburgerAction(action) && !isEqual<ToggleHamburgerAction>(action, state)) {
    return {
      ...state,
      isHamburgerOpen: action.isHamburgerOpen,
    };
  }

  if (isActivateDropdownAction(action) && !isEqual<ActivateDropdownAction>(action, state)) {
    return {
      ...state,
      activeDropdownId: action.activeDropdownId,
    };
  }

  if (isMoveIndicatorAction(action) && !isEqual<MoveIndicatorAction>(action, state)) {
    return {
      ...state,
      indicatorPos: action.indicatorPos,
    };
  }

  return state;
};

export default navigationReducer;
