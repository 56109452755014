import React, { FunctionComponent } from 'react';
import ModalContentStyle from './ModalContentStyle';
import ShippingModal from './shippingModal/ShippingModal';
import SizeGuide from './sizeGuide/SizeGuide';

interface Props {
  id: string;
}

const ModalContent: FunctionComponent<Props> = ({ id }) => {
  switch (id) {
    case 'copyright':
      return (
        <ModalContentStyle>
          <h3>Copyright notice</h3>
          <p>
            &copy;
            {` ${new Date().getFullYear()} Tirette, all rights reserved. TIRETTE`}
            &reg;, its logo and distinctive zipper are trademarks of Tirette.
          </p>
        </ModalContentStyle>
      );
    case 'shipping':
      return <ShippingModal />;
    case 'succeeded':
      return (
        <ModalContentStyle>
          <h3>Thank you!</h3>
          <p>
            Your order is successful!
            You will receive an email soon with your order details.
            Enjoy your new jeans!
          </p>
        </ModalContentStyle>
      );
    case 'failed':
      return (
        <ModalContentStyle>
          <h3>Oops, your order failed!</h3>
          <p>
            Seems like something went wrong while processing your order.
            Please try again or use a different card.
          </p>
        </ModalContentStyle>
      );
    case 'maleSizeGuide':
      return <SizeGuide gender="male" />;
    case 'femaleSizeGuide':
      return <SizeGuide gender="female" />;
    default:
      return null;
  }
};

export default ModalContent;
