import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTransition } from 'react-spring';
import { transition as config } from 'animationConfig';
import useGlobalState from 'hooks/globalState/useGlobalState';
import { setModal as setModalAction } from 'hooks/globalState/actions/modalActions/setModal';
import useDispatch from 'hooks/globalState/useDispatch';
import useOnClose from 'hooks/useOnClose';
import Track from 'lib/tracker';
import ModalStyle from './ModalStyle';
import Card from '../../style/Card';
import ModalContent from './modalContent/ModalContent';

const Modal: FunctionComponent = () => {
  const { id, onClose } = useGlobalState('modal');
  const setModal = useDispatch(setModalAction);
  const isVisible = !!id;
  const transitions = useTransition(isVisible, null, {
    config,
    from: { transform: 'scale(0)', opacity: 0 },
    enter: { transform: 'scale(1)', opacity: 1 },
    leave: { transform: 'scale(0)', opacity: 0 },
  });

  const onCloseModal = useCallback((): void => {
    setModal({ id: undefined, onClose: undefined });
    if (onClose) {
      onClose();
    }
  }, [onClose, setModal]);

  const parent = useOnClose<HTMLElement>(onCloseModal);

  useEffect(() => {
    if (id) {
      Track.modalview(id);
    }
  }, [id]);

  return (
    <>
      {transitions.map(({ item, props }) => item && createPortal(
        <ModalStyle
          aria-modal
          ref={parent}
          style={{ opacity: props.opacity }}
        >
          <Card style={{ transform: props.transform }}>
            <button
              className="close"
              type="button"
              onClick={onCloseModal}
              aria-label="Close Modal"
              aria-labelledby="close-modal"
            >
              <span />
              <span />
            </button>
            {id && <ModalContent id={id} />}
          </Card>
        </ModalStyle>,
        document.body,
      ))}
    </>
  );
};

export default Modal;
