import React, { useState, useEffect, useRef, FunctionComponent } from 'react';

import Button from 'style/Button';
import Card from 'style/Card';
import Color from 'style/Color';
import Slider from 'components/slider/Slider';
import debounce from 'utils/debounce';
import Image from 'components/Image';
import ProductCardStyle from './ProductCardStyle';

interface ColorType {
  color: string;
  image: string;
}

interface Card {
  title: string;
  subtitle: string;
  cta: string;
  url: string;
  colors: ColorType[];
}

interface Props {
  card: Card;
}

const ProductCard: FunctionComponent<Props> = ({ card }) => {
  const [width, setWidth] = useState(0);
  const [activeColor, setActiveColor] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const onResize = debounce(() => {
    if (ref.current) { setWidth(ref.current.clientWidth); }
  }, 100, true);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return (): void => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  useEffect(() => {
    if (ref && ref.current) {
      setWidth(ref.current.clientWidth);
    }
  }, [ref]);

  return (
    <ProductCardStyle ref={ref}>
      <Card height={width}>
        <Slider index={activeColor}>
          {card.colors.map((color) => (
            <Image key={color.color} src={color.image} alt={color.color} />
          ))}
        </Slider>
        <div className="overlay">
          <h2>{card.title}</h2>
          <h4>{card.subtitle}</h4>
          <div className="colors">
            {card.colors.map((color, i) => (
              <Color
                key={color.image}
                onClick={(): void => setActiveColor(i)}
                color={color.color}
                active={activeColor === i}
              />
            ))}
          </div>
          <Button to={card.url}>{card.cta}</Button>
        </div>
      </Card>
    </ProductCardStyle>
  );
};

export default ProductCard;
