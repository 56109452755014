import styled from 'styled-components';
import NewspaperCardStyle from 'components/cards/newspaperCard/NewspaperCardStyle';
import bgImg from 'assets/img/newspaper_bg.jpg';
import bgImg800 from 'assets/img/newspaper_bg_800.jpg';
import bgImg400 from 'assets/img/newspaper_bg_400.jpg';

const ConceptPageNewspapersStyle = styled.section`
  background-image: url(${bgImg});
  background-size: contain;
  background-position-y: 12rem;
  background-repeat: no-repeat;

  .container {  
    width: 100%;
    display: flex;
    justify-content: space-between;

    ${NewspaperCardStyle} {
      :first-child {
        margin-top: 3.2rem;
      }

      :nth-child(2) {
        margin-top: 21.6rem;
        margin-bottom: 10rem;
      }

      :last-child {
        margin-top: 11.2rem;
      }
    }
  }

  @media (max-width: 800px) {
    background-image: url(${bgImg800});

    .container {
      flex-wrap: wrap;

      ${NewspaperCardStyle} {
        width: calc(50% - 0.8rem);
        padding-top: calc((50% - 0.8rem) * 1.44);
        
        :first-child {
          margin-top: 0;
        }

        :nth-child(2) {
          margin-top: calc((50% - 0.8rem) * 0.77);
          margin-bottom: 0;
        }

        :last-child {
          margin-top: calc((-50% + 3.2rem) * 0.77);
          margin-bottom: 8rem;
        }
      }
    }
  }

  @media (max-width: 400px) {
    background-image: url(${bgImg400});

    .container {
      flex-direction: column;
      align-items: center;

      ${NewspaperCardStyle} {
        width: 90%;
        padding-top: calc(90% * 1.44);
        margin-bottom: 1.6rem;

        :nth-child(2) {
          margin-top: 0;
          margin-bottom: 1.6rem;
        }

        :last-child {
          margin-top: 0;
        }
      }
    }
  }
`;

export default ConceptPageNewspapersStyle;
