import styled from 'styled-components';
import { colors } from 'style/variables';

interface Props {
  disabled?: boolean;
  active?: boolean;
}

const getColor = (active?: boolean, disabled?: boolean): string => {
  switch (true) {
    case active: return 'white';
    case disabled: return colors.disabled;
    default: return colors.black;
  }
};

const Size = styled.div<Props>`
  width: 3.2rem;
  height: 3.2rem;
  border: ${({ disabled }: Props): string => (disabled ? `2px solid ${colors.disabled}` : `2px solid ${colors.black}`)};
  border-radius: .8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 1.6rem 1.6rem 0;
  background-color: ${({ active }: Props): string => (active ? colors.black : 'transparent')};
  will-change: background-color;
  transition: background-color .2s ease;

  p {
    color: ${({ active, disabled }: Props): string => getColor(active, disabled)};
    will-change: color;
    transition: color .2s ease;
  }

  :hover {
    background-color: ${({ disabled }: Props): string => (disabled ? 'transparent' : colors.black)};
    cursor: ${({ disabled }: Props): 'default' | 'pointer' => (disabled ? 'default' : 'pointer')};

    p {
      color: ${({ disabled }: Props): string => (disabled ? colors.disabled : 'white')};
    }
  }
`;

export default Size;
