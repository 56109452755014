import React, { FunctionComponent, MouseEvent } from 'react';

import ProductInfoStyle from './ProductInfoStyle';

interface Props {
  title: string;
  cta?: string;
  onClick?(e: MouseEvent): void;
}

const ProductInfo: FunctionComponent<Props> = ({ title, cta, onClick, children }) => (
  <ProductInfoStyle>
    <div className="top">
      <p>{title}</p>
      {cta && <button type="button" onClick={onClick}>{cta}</button>}
    </div>
    {children}
  </ProductInfoStyle>
);

export default ProductInfo;
