import styled from 'styled-components';
import { animated } from 'react-spring';
import Card from 'style/Card';
import { colors } from 'style/variables';

const ModalStyle = styled(animated.aside).attrs({ 'data-testid': 'modal' })`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(51, 51, 51, .5);
  backdrop-filter: blur(5px);
  z-index: 2;

  ${Card} {
    width: 90vw;
    height: 55.6vw;
    max-width: 64.7rem;
    max-height: 40rem;
    background-color: rgba(255, 255, 255, .7);
    border: none;
    padding: .8rem;

    ::after {
      display: none;
    }
  }

  .close {
    position: absolute;
    top: 1.4rem;
    right: 2.4rem;
    width: 2.4rem;
    height: 2.4rem;

    span {
      display: block;
      width: 2.1rem;
      height: 0.4rem;
      right: -.4rem;
      top: 1.2rem;
      position: absolute;
      background-color: ${colors.black};
      border-radius: 0.3rem;
      transform-origin: 50% 50%;
      transform: rotate(45deg);

      :last-child{
        transform-origin: 50% 50%;
        transform: rotate(-45deg);
      }
    }
  }

  @media (max-width: 600px) {
    ${Card} {
      height: 80vh;
      max-height: none;
    }
  }
`;

export default ModalStyle;
