import styled from 'styled-components';
import BackgroundImage from './BackgroundImage';

interface Props {
  width?: string | number;
  height?: string | number;
}

const Card = styled(BackgroundImage)<Props>`
  width: ${({ width }): string | number => (width || '')};
  height: ${({ height }): string | number => (height || '')};
  border: 0.8rem solid white;
  background-color: white;
  position: relative;
  margin-bottom: 1.6rem;
  background-size: cover;
  background-position: center;

  ::after {
    content: '';
    display: block;
    width: 98%;
    height: 85%;
    box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.2);
    left: 1%;
    bottom: 0;
    z-index: -1;
    position: absolute;
  }
`;

export default Card;
