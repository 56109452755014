import React, { FunctionComponent } from 'react';
import Header from 'components/navigation/header/Header';
import useAboveTheFoldHeight from 'hooks/useAboveTheFoldHeight';

const ProductHeader: FunctionComponent = () => {
  const height = useAboveTheFoldHeight();

  return (
    <>
      <Header
        theme="black"
        height={height}
        botLine="calc(50% + 61.6rem) 100%, calc(50% + 61.6rem) calc(100% - 5.2rem), calc(50% + 19.2rem) calc(100% - 5.2rem), calc(50% + 19.2rem) 100%, calc(50% - 19.2rem) 100%, calc(50% - 19.2rem) calc(100% - 2.8rem), calc(50% - 61.6rem) calc(100% - 2.8rem), calc(50% - 61.6rem) 100%,"
        mediaQuery={{
          '1369px': {
            botLine: 'calc(50% + 45vw) 100%, calc(50% + 45vw) calc(100% - 5.2rem), calc(50% + 13.667vw) calc(100% - 5.2rem), calc(50% + 13.667vw) 100%, calc(50% - 13.667vw) 100%, calc(50% - 13.667vw) calc(100% - 2.8rem), calc(50% - 45vw) calc(100% - 2.8rem), calc(50% - 45vw) 100%,',
          },
          '800px': {
            botLine: '',
          },
        }}
      />
      <Header
        height={`calc(100% - (${height} - 5.2rem))`}
        top={`calc(${height} - 5.2rem)`}
        topLine="0% 5.2rem, calc(50% - 61.6rem) 5.2rem, calc(50% - 61.6rem) 2.4rem, calc(50% - 19.2rem) 2.4rem, calc(50% - 19.2rem) 5.2rem, calc(50% + 19.2rem) 5.2rem, calc(50% + 19.2rem) 0%, calc(50% + 61.6rem) 0%, calc(50% + 61.6rem) 5.2rem, 100% 5.2rem,"
        mediaQuery={{
          '1369px': {
            topLine: '0% 5.2rem, calc(50% - 45vw) 5.2rem, calc(50% - 45vw) 2.4rem, calc(50% - 13.667vw) 2.4rem, calc(50% - 13.667vw) 5.2rem, calc(50% + 13.667vw) 5.2rem, calc(50% + 13.667vw) 0%, calc(50% + 45vw) 0%, calc(50% + 45vw) 5.2rem, 100% 5.2rem,',
          },
          '800px': {
            topLine: '0% 5.2rem, 100% 5.2rem,',
          },
        }}
      />
    </>
  );
};

export default ProductHeader;
