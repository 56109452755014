import styled, { ThemeProps } from 'styled-components';
import { Theme } from 'types';

const NavBarLinkStyle = styled.li<ThemeProps<Theme>>`
  position: relative;

  :hover {
    padding-bottom: 4rem;
    margin-bottom: -4rem;
  }

  button, a {
    color: ${({ theme }): string => theme.color};
    will-change: color;
    transition: color 0.3s ease;
    padding: 0;
  }

  button {
    margin-bottom: -0.4rem;
  }

  .dropdown {
    display: flex;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    flex-direction: column;
    margin: 2rem 0 0 -4rem;
    padding: 1.5rem 6rem 1.5rem 4rem;
  }

  .dropdown-link {
    margin: 1rem 0;
    color: #c4c4c4;
    will-change: color;
    transition: color 0.3s;

    :hover {
      color: white;
    }
  }
`;

export default NavBarLinkStyle;
