import ActionTypes from '../types';
import { Action } from '../action';

interface MoveIndicatorActionOptions {
  indicatorPos: { width: number; left: number };
}

export interface MoveIndicatorAction extends Action {
  indicatorPos: { width: number; left: number };
}

export const moveIndicator = (data: MoveIndicatorActionOptions): MoveIndicatorAction => ({
  type: ActionTypes.MoveIndicator,
  indicatorPos: data.indicatorPos,
});

export const isMoveIndicatorAction = (action: Action): action is MoveIndicatorAction => (
  action.type === ActionTypes.MoveIndicator
);
