import React, { FunctionComponent, useEffect, useState } from 'react';
import Header from 'components/navigation/header/Header';
import useGlobalState from 'hooks/globalState/useGlobalState';
import useAboveTheFoldHeight from 'hooks/useAboveTheFoldHeight';

const ConceptHeader: FunctionComponent = () => {
  const [offset, setOffset] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const { clientWidth } = useGlobalState('main');
  const aboveTheFoldHeight = useAboveTheFoldHeight();

  useEffect(() => {
    const [element] = document.getElementsByClassName('clip');
    const elementRect = element.getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();

    setOffset(elementRect.top - bodyRect.top);
    setHeight(elementRect.height);
  }, [clientWidth]);

  return (
    <>
      <Header
        theme="white"
        height={aboveTheFoldHeight}
        botLine="calc(50% - 18.5rem) 100%, calc(50% - 18.5rem) calc(100% - 5.2rem), calc(50% - 61.6rem) calc(100% - 5.2rem), calc(50% - 61.6rem) 100%,"
        mediaQuery={{
          '1369px': {
            botLine: 'calc(5% + (29.7vw + 2.4rem)) 100%, calc(5% + (29.7vw + 2.4rem)) calc(100% - 5.2rem), calc(50% - 45vw) calc(100% - 5.2rem), calc(50% - 45vw) 100%,',
          },
          '500px': {
            botLine: 'calc(50% + 45vw) 100%, calc(50% + 45vw) calc(100% - 5.2rem), calc(50% - 45vw) calc(100% - 5.2rem), calc(50% - 45vw) 100%,',
          },
        }}
      />
      <Header
        height={`calc(${offset}px - (${aboveTheFoldHeight} - 5.2rem) + ${height * 0.15}px)`}
        top={`calc(${aboveTheFoldHeight} - 5.2rem)`}
        topLine="0% 5.2rem, calc(50% - 61.6rem) 5.2rem, calc(50% - 61.6rem) 0%, calc(50% - 18.5rem) 0%, calc(50% - 18.5rem) 5.2rem, 100% 5.2rem,"
        botLine={`100% calc(100% - ${height * 0.15}px),`}
        mediaQuery={{
          '1369px': {
            topLine: '0% 5.2rem, calc(50% - 45vw) 5.2rem, calc(50% - 45vw) 0%, calc(5% + (29.7vw + 2.4rem)) 0%, calc(5% + (29.7vw + 2.4rem)) 5.2rem, 100% 5.2rem,',
            botLine: `100% calc(100% - ${height * 0.15}px),`,
          },
          '500px': {
            topLine: '0% 5.2rem, calc(50% - 45vw) 5.2rem, calc(50% - 45vw) 0%, calc(50% + 45vw) 0%, calc(50% + 45vw) 5.2rem, 100% 5.2rem,',
            botLine: `100% calc(100% - ${height * 0.15}px), 0% calc(100% - ${height * 0.05}px),`,
          },
        }}
      />
      <Header
        theme="white"
        height={height}
        top={offset}
        topLine="0 15%,"
        mediaQuery={{
          '500px': {
            topLine: '0 10%,',
          },
        }}
      />
    </>
  );
};

export default ConceptHeader;
