import styled from 'styled-components';
import { colors } from 'style/variables';
import { SecondaryButton } from 'style/Button';

const ConsentBoxStyle = styled.div`
  h3 {
    margin-bottom: 3.2rem;
  }

  ${SecondaryButton} {
    margin-bottom: 0;
    margin-left: 4rem;
  }

  .link {
    font-size: 1.2rem;
    color: ${colors.link};
    text-decoration: underline;
    cursor: pointer;
  }

  .cookie-icon {
    position: absolute;
    top: 0;
    right: .8rem;
  }

  .actions {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 3.2rem;
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 8.75vw;
      margin-bottom: 0;
    }

    ${SecondaryButton} {
      padding-right: 0;
      margin-left: 0;
      margin-top: 1.6rem;
    }

    .cookie-icon {
      position: relative;
    }

    .actions {
      flex-direction: column;
    }
  }
`;

export default ConsentBoxStyle;
