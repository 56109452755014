import styled from 'styled-components';
import { colors, fonts } from 'style/variables';

interface GroupProps {
  amount: number;
}

const StripeInputStyle = styled.div`
  margin-bottom: 1.6rem;
  font-family: ${fonts.secondary};
  font-size: 1.4rem;
  color: ${colors.black};

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid ${colors.disabled};
    will-change: border-bottom;
    transition: border-bottom .2s ease;

    label {
      margin-top: 2rem;
      margin-left: .8rem;
      color: ${colors.disabled};
      will-change: margin-top, color, font-size;
      transition: margin-top .2s ease, color .2s ease, font-size .2s ease;
    }

    .StripeElement {
      padding-left: .8rem;
      border: none;
      font-family: inherit;
      font-size: inherit;
      height: .4rem;
      color: transparent;
      opacity: 0;
      will-change: height, opacity;
      transition: height .2s ease, opacity .2s ease;
    }
  }

  .focus, .error, .success {
    label {
      margin-top: 0;
      font-size: 1.2rem;
    }

    .StripeElement {
      outline: none;
      height: 2.4rem;
      color: ${colors.black};
      opacity: 1;
    }
  }

  .error {
    border-bottom: 1px solid ${colors.error};

    label {
      color: ${colors.error};
    }
  }

  .success {
    border-bottom: 1px solid ${colors.success};

    label {
      color: ${colors.success};
    }
  }
`;

export const StripeInputGroup = styled.div<GroupProps>`
  display: flex;
  justify-content: space-between;

  ${StripeInputStyle} {
    width: ${({ amount }): string => `calc(( 100% / ${amount} ) - 0.4rem)`};
  }
`;

export default StripeInputStyle;
