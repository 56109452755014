import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import { Theme, HeaderMediaQuery } from 'types';
import importAll from 'utils/importAll';
import useGlobalState from 'hooks/globalState/useGlobalState';
import NavBar from 'components/navigation/navBar/NavBar';
import SocialNav from 'components/navigation/socialNav/SocialNav';
import Hamburger from 'components/navigation/hamburger/Hamburger';
import products, { Product } from 'assets/data/collection';
import { colors } from 'style/variables';
import Link from '../Link';
import HeaderStyle from './HeaderStyle';

export interface DropdownLink {
  page: string;
  url: string;
}

export interface Link {
  id: string;
  page: string;
  path: string;
  url?: string;
  dropdown?: DropdownLink[];
}

interface Props {
  theme?: 'white' | 'black';
  height?: number | string;
  top?: number | string;
  topLine?: string;
  botLine?: string;
  mediaQuery?: HeaderMediaQuery;
}

const white: Theme = {
  bg: 'transparent',
  color: 'white',
};

const black: Theme = {
  bg: 'transparent',
  color: colors.black,
};

const main: Theme = {
  bg: 'rgba(255, 255, 255, 0.7)',
  color: colors.black,
};

const Header: FunctionComponent<Props> = (
  { theme, height = '100%', top = 0, topLine = '', botLine = '', mediaQuery = {} },
) => {
  const { clientWidth } = useGlobalState('main');
  const { isHamburgerOpen } = useGlobalState('navigation');

  const parseProducts = (p: Product[] | null): DropdownLink[] => (
    p
      ? p.map((_p: Product) => ({ page: _p.url, url: `/collection/${_p.url}` }))
      : []
  );

  const links: Link[] = [
    { id: 'topnavHome', page: 'Home', path: '', url: '/' },
    { id: 'topnavShop', page: 'Shop', path: 'collection', dropdown: parseProducts(products) },
    { id: 'topnavConcept', page: 'Concept', path: 'concept', url: '/concept' },
  ];

  const getVariable = <V extends unknown = string>(arr: V[]): V => {
    const color = isHamburgerOpen ? 'white' : theme;

    switch (color) {
      case 'white': return arr[0];
      case 'black': return arr[1];
      default: return arr[2];
    }
  };

  const activeTheme = getVariable<Theme>([white, black, main]);
  const logoUrl = getVariable(['logo_white.svg', 'logo_black.svg', 'logo_black.svg']);
  const iconUrl = getVariable(['icon_white.svg', 'icon_black.svg', 'icon_black.svg']);
  const [logo] = importAll(require.context('../../../assets/svg', false, /\.svg$/), logoUrl);
  const [icon] = importAll(require.context('../../../assets/svg/', false, /\.svg$/), iconUrl);

  return (
    <ThemeProvider theme={activeTheme}>
      <HeaderStyle
        bgColor={activeTheme.bg}
        height={isHamburgerOpen ? '100%' : height}
        top={isHamburgerOpen ? 0 : top}
        topLine={isHamburgerOpen ? '' : topLine}
        botLine={isHamburgerOpen ? '' : botLine}
        mediaQuery={isHamburgerOpen ? {} : mediaQuery}
      >
        <div className="container">
          <Link to="/" className="logo">
            <img src={logo} alt="Tirette" height="30" width="94" />
          </Link>
          {clientWidth > 700 && (
            <>
              <Link to="/" className="icon">
                <img src={icon} alt="Tirette" height="30" />
              </Link>
              <NavBar links={links} />
              <SocialNav theme={theme} />
            </>
          )}
          {clientWidth <= 700 && (
            <Hamburger links={links} />
          )}
        </div>
      </HeaderStyle>
    </ThemeProvider>
  );
};

export default Header;
