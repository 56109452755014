import styled from 'styled-components';
import Button from 'style/Button';
import { colors, fonts } from 'style/variables';

const CheckoutFormStyle = styled.div`
  width: 29rem;
  display: flex;
  flex-direction: column;
  font-family: ${fonts.secondary};
  font-size: 1.4rem;

  ${Button} {
    margin-top: 2.4rem;
    font-family: ${fonts.secondary};
  }

  h3, h4, p {
    color: ${colors.black};
    margin: 1.6rem 0 .8rem;
  }

  h3, h4 {
    margin-top: 0;
  }

  h3 {
    position: relative;

    ::after {
      content: '';
      display: block;
      width: 60%;
      height: 1px;
      bottom: 0;
      left: 0;
      border-bottom: .4rem solid ${colors.black};
      margin-left: .2rem;
      margin-top: .8rem;
    }
  }

  .terms-link {
    color: ${colors.link};
    text-decoration: underline;
  }

  .order-info {
    display: flex;
    justify-content: space-between;
  }

  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .shipping-info {
    margin-left: 0.9rem;
    min-height: 2.3rem;
  }
`;

export default CheckoutFormStyle;
