const getCookie = (name: string): string | boolean | number | undefined => {
  const isBoolean = (str?: string): boolean => str === 'true' || str === 'false';
  const isNumber = (str?: string): boolean => !!parseInt(str || '', 10);
  const matches = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
  const value = matches?.pop();

  if (isBoolean(value)) {
    return value === 'true';
  }

  if (isNumber(value)) {
    return parseInt(value || '', 10);
  }

  return value;
};

export default getCookie;
