interface Country {
  name: string;
  shippingRate: number;
}

const countries: Country[] = [
  {
    name: 'Belgium',
    shippingRate: 0,
  },
  {
    name: 'France',
    shippingRate: 800,
  },
  {
    name: 'Germany',
    shippingRate: 800,
  },
  {
    name: 'Luxembourg',
    shippingRate: 800,
  },
  {
    name: 'The Netherlands',
    shippingRate: 800,
  },
  {
    name: 'United Kingdom',
    shippingRate: 800,
  },
];

export default countries;
