import React, { useEffect, useState, FunctionComponent } from 'react';

import Button from 'style/Button';
import Color from 'style/Color';
import ProductInfo from 'components/webshop/productInfo/ProductInfo';
import Size from 'style/Size';
import removeDuplicates from 'utils/removeDuplicates';
import products, { Product, Sku } from 'assets/data/collection';
import { setModal } from 'hooks/globalState/actions/modalActions/setModal';
import useDispatch from 'hooks/globalState/useDispatch';
import Track from 'lib/tracker';
import CustomizationFormStyle from './CustomizationFormStyle';

interface Currencies {
  [key: string]: string;
}

interface Props {
  productName: string;
  activeColor: string;
  setSku(skuId: Sku | null): void;
  setActiveColor(name: string): void;
}

const CustomizationForm: FunctionComponent<Props> = ({
  productName, activeColor, setSku, setActiveColor,
}) => {
  const openModal = useDispatch(setModal);
  const product = products.filter((p: Product) => p.name === productName)[0];
  const { skus, colors } = product;
  const sizes = removeDuplicates<string>(skus.map((sku) => sku.attributes.size));
  const [activeSize, setActiveSize] = useState(sizes[0]);
  const currencies: Currencies = { eur: '€' };

  const getSku = (size: string, color: string): Sku | null => {
    let sku;

    if (skus) {
      [sku] = skus?.filter((_sku) => (
        _sku.attributes.color === color && _sku.attributes.size === size
      ));
    }

    return sku || null;
  };

  const getSkuAttr = (attr: keyof Sku, size: string, color: string): Sku[keyof Sku] | 0 => {
    const sku = getSku(size, color);

    return sku ? sku[attr] : 0;
  };

  const onClick = (): void => {
    setSku(getSku(activeSize, activeColor));
    Track.event('button', 'click', 'buy now');
  };

  useEffect(() => {
    const newSizes = removeDuplicates<string>(skus.map((sku) => sku.attributes.size));

    setActiveSize(newSizes[0]);
  }, [productName, skus]);

  return (
    <CustomizationFormStyle>
      <div className="top">
        <h4>Free shipping in Belgium</h4>
        <h3 className="price">
          <p className="currency">{currencies[getSkuAttr('currency', activeSize, activeColor) as string]}</p>
          {Math.floor((getSkuAttr('price', activeSize, activeColor) as number) / 100)}
          <p className="currency">
            ,
            {(getSkuAttr('price', activeSize, activeColor) as number) % 100}
          </p>
        </h3>
      </div>
      <h2>{productName}</h2>
      <ProductInfo title="Color">
        <div className="colors">
          {colors.map(({ name, hex }) => (
            <Color
              key={hex}
              dark
              color={hex}
              active={activeColor === name}
              onClick={(): true | void => getSkuAttr('inventory', activeSize, name) === 0 || setActiveColor(name)}
              disabled={getSkuAttr('inventory', activeSize, name) === 0}
            />
          ))}
        </div>
      </ProductInfo>
      <ProductInfo title="Size" cta="Size guide" onClick={(): void => openModal({ id: product.url === 'men' ? 'maleSizeGuide' : 'femaleSizeGuide' })}>
        <div className="sizes">
          {sizes.map((size) => (
            <Size
              key={size}
              active={activeSize === size}
              onClick={(): true | void => getSkuAttr('inventory', size, activeColor) === 0 || setActiveSize(size)}
              disabled={getSkuAttr('inventory', size, activeColor) === 0}
            >
              <p>{size}</p>
            </Size>
          ))}
        </div>
      </ProductInfo>
      <ProductInfo title="Not living in Belgium?" cta="Shipping" onClick={(): void => openModal({ id: 'shipping' })}>
        <button type="button" onClick={(): void => openModal({ id: 'shipping' })}>Calculate shipping</button>
      </ProductInfo>
      <Button to="#" dark onClick={onClick} aria-label="Buy now">
        Buy now
      </Button>
    </CustomizationFormStyle>
  );
};

export default CustomizationForm;
