import { withRouter, RouteComponentProps } from 'react-router-dom';
import React, { useState, FunctionComponent, useEffect } from 'react';

import { useTransition, animated } from 'react-spring';
import useGlobalState from 'hooks/globalState/useGlobalState';
import products, { Product, Feature, Sku, Info } from 'assets/data/collection';
import Card from 'style/Card';
import CheckoutForm from 'components/webshop/checkoutForm/CheckoutForm';
import CustomizationForm from 'components/webshop/customizationForm/CustomizationForm';
import ProductInfo from 'components/webshop/productInfo/ProductInfo';
import Wrapper from 'style/Wrapper';
import { transition as config } from 'animationConfig';
import { setModal } from 'hooks/globalState/actions/modalActions/setModal';
import useDispatch from 'hooks/globalState/useDispatch';
import AboveTheFold from 'style/AboveTheFold';
import ProductPageHeader from './ProductPageHeader';
import ProductPageStyle from './ProductPageStyle';

const AnimatedCard = animated(Card);

const ProductPage: FunctionComponent<RouteComponentProps<{ id: string }>> = ({
  match, history, location,
}) => {
  const openModal = useDispatch(setModal);
  const { clientWidth } = useGlobalState('main');
  const [sku, setSku] = useState<Sku | null>(null);
  const [paymentStatus, setPaymentStatus] = useState<string>();
  const product = products.filter((p: Product) => p.url === match.params.id)[0];
  const [color, setColor] = useState<string>(product.colors[0].name);
  const transitions = useTransition(color, null, {
    config,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 9 },
  });

  if (paymentStatus === 'succeeded') {
    openModal({ id: 'succeeded',
      onClose: () => {
        setPaymentStatus(undefined);
        history.push('/');
      } });
  } else if (paymentStatus && paymentStatus !== 'succeeded') {
    openModal({ id: 'failed',
      onClose: () => {
        setPaymentStatus(undefined);
      } });
  }

  const renderForm = (p: Product): JSX.Element => {
    if (sku) {
      return (
        <CheckoutForm
          sku={sku}
          productName={p.name}
          setPaymentStatus={setPaymentStatus}
          onCancel={(): void => setSku(null)}
        />
      );
    }

    return (
      <CustomizationForm
        productName={p.name}
        activeColor={color}
        setSku={setSku}
        setActiveColor={setColor}
      />
    );
  };

  useEffect(() => {
    setSku(null);
    setColor(product.colors[0].name);
  }, [location.pathname, product.colors]);

  return (
    <ProductPageStyle>
      <AboveTheFold>
        <div className="header-image-container">
          <ProductPageHeader
            productName={product.name}
            headerImages={product.images}
            featureImages={product.features.map((feature) => feature.image)}
            color={color}
          />
        </div>
        {clientWidth > 800 && renderForm(product)}
      </AboveTheFold>
      <main>
        {clientWidth <= 800 && renderForm(product)}
        <Wrapper>
          {clientWidth > 800 && (
            <div className="cards">
              <p className="quote">Designed for those who dare to live</p>
              {product.features.map(({ image, title }: Feature) => (
                <div className="card-container" key={title}>
                  {transitions.map(({ item, key, props }) => (
                    <AnimatedCard image={image[item]} key={`${key}${title}`} style={props}>
                      <p>{title}</p>
                    </AnimatedCard>
                  ))}
                </div>
              ))}
            </div>
          )}
          <div className="info">
            {product.info.map((info: Info) => (
              <ProductInfo key={info.title} title={info.title} cta={info.cta}>
                <p>{info.info}</p>
              </ProductInfo>
            ))}
          </div>
        </Wrapper>
      </main>
    </ProductPageStyle>
  );
};

export default withRouter(ProductPage);
