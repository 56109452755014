import React, { FunctionComponent } from 'react';

import links from 'assets/data/links';
import importAll from 'utils/importAll';
import Link from 'components/navigation/Link';
import SocialNavStyle from './SocialNavStyle';

interface Props {
  theme?: 'white' | 'black';
}

const SocialNav: FunctionComponent<Props> = ({ theme = 'black' }) => {
  const images: string[] = [];

  links.social.forEach((link) => {
    images.push(
      importAll(require.context('../../../assets/svg/', false, /\.svg/), `${link.image}-${theme}.svg`)[0],
    );
  });

  return (
    <SocialNavStyle>
      <ul>
        {links.social.map((data, i) => (
          <li key={data.alt}>
            <Link to={data.url} className="link">
              <img
                src={images[i]}
                alt={data.alt}
                height="20"
                width="20"
              />
            </Link>
          </li>
        ))}
      </ul>
    </SocialNavStyle>
  );
};

export default SocialNav;
