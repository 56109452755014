import styled from 'styled-components';
import { colors } from 'style/variables';
import AboveTheFold from 'style/AboveTheFold';
import BackgroundImage from 'style/BackgroundImage';

const ConceptPageStyle = styled.section`

  ${AboveTheFold} {
    width: 100vw;
  }

  ${BackgroundImage} {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    width: 73.3rem;
    line-height: 10.8rem;
    text-align: center;
  }

  h3 {
    color: ${colors.black};
    text-align: center;
    margin: 8rem 0;
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 15vw;
      line-height: 18vw;
    }
  }
`;

export default ConceptPageStyle;
