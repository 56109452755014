import styled, { ThemeProps } from 'styled-components';
import { animated } from 'react-spring';
import { Theme } from 'types';
import { fonts } from 'style/variables';

interface NavBarProps {
  theme: Theme;
}

const NavBarStyle = styled.nav<NavBarProps>`
  width: calc(100vw - 27rem);
  display: flex;
  justify-content: flex-end;

  ul {
    width: 32rem;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-family: ${fonts.secondary};
    right: 10rem;
    margin-top: ${({ theme }): string | number => theme.marginTop};
    will-change: margin-top;
    transition: margin-top 0.3s ease;
  }
`;

export const PageIndicator = styled(animated.span)<ThemeProps<Theme>>`
  height: 0.4rem;
  display: block;
  position: fixed;
  top: 0;
  background-color: ${({ theme }): string => theme.color};
  will-change: width, left, background-color;
`;

export default NavBarStyle;
