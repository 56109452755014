import React, { FunctionComponent, MouseEvent } from 'react';
import Link from 'components/navigation/Link';
import { useTransition, animated } from 'react-spring';
import useGlobalState from 'hooks/globalState/useGlobalState';
import { Link as ILink } from 'components/navigation/header/Header';
import { hover as config } from 'animationConfig';

import { activateDropdown } from 'hooks/globalState/actions/navigationActions/activateDropdown';
import useDispatch from 'hooks/globalState/useDispatch';
import NavBarLinkStyle from './NavBarLinkStyle';

interface Props {
  link: ILink;
  active: boolean;
  moveIndicatorToElement(element: Element): void;
}

const NavBarLink: FunctionComponent<Props> = ({ link, active, moveIndicatorToElement }) => {
  const { activeDropdownId } = useGlobalState('navigation');
  const setActiveDropdown = useDispatch(activateDropdown);
  const activeLink = document.getElementsByClassName('active-link')[0];
  const isDropdownOpen = activeDropdownId === link.id;
  const transitions = useTransition(isDropdownOpen, null, {
    config,
    from: { opacity: 0, pointerEvents: 'none', transform: 'translateY(-5px)' },
    enter: { opacity: 1, pointerEvents: 'auto', transform: 'translateY(0px)' },
    leave: { opacity: 0, pointerEvents: 'none', transform: 'translateY(-5px)' },
  });

  const interactions = {
    onMouseOver: (e: MouseEvent<HTMLLIElement>): void => {
      moveIndicatorToElement(e.currentTarget);
      setActiveDropdown({ activeDropdownId: link.dropdown && link.id });
    },
    onMouseLeave: (): void => {
      moveIndicatorToElement(activeLink);
      setActiveDropdown({ activeDropdownId: undefined });
    },
  };

  return (
    <NavBarLinkStyle {...interactions}>
      {link.dropdown && (
        <>
          <button type="button" className={active ? 'active-link' : ''}>{link.page}</button>
          {transitions.map(({ item, key, props }) => item && (
            <animated.div className="dropdown" key={key} style={props}>
              {link.dropdown?.map((dropdownLink) => (
                <Link key={dropdownLink.page} to={dropdownLink.url} className="dropdown-link">
                  {dropdownLink.page}
                </Link>
              ))}
            </animated.div>
          ))}
        </>
      )}
      {!link.dropdown && (
        <Link to={link.url || '#'} className={active ? 'active-link' : ''}>{link.page}</Link>
      )}
    </NavBarLinkStyle>
  );
};

export default NavBarLink;
