import { PureComponent, ReactNode } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

class ScrollToTop extends PureComponent<RouteComponentProps> {
  componentDidUpdate(prevProps: RouteComponentProps): void {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render(): ReactNode {
    const { children } = this.props;
    return children;
  }
}

export default withRouter(ScrollToTop);
