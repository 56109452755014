import styled from 'styled-components';

import Button from 'style/Button';
import Card from 'style/Card';

const ProductCardStyle = styled.div`
  width: calc((100% - 1.6rem)/2);

  ${Card} {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${Button} {
    margin-top: 3.2rem;
  }

  h2 {
    text-align: center;
    font-size: 6.4rem;

    ::after {
      content: '';
      position: absolute;
      width: 24%;
      height: 6.6rem;
      left: 38%;
      border-bottom: 0.1rem solid white;
    }
  }

  h4 {
    margin-top: 0.6rem;
  }

  picture {
    min-width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .colors {
    margin: 2.4rem 0;
    display: flex;
  }

  @media (max-width: 1200px) {

    ${Button} {
      margin-top: 2.4rem;
    }

    h2 {
      font-size: 4.75vw;

      ::after {
        height: 4.9vw;
      }
    }

    .colors {
      margin: 1.6rem 0;
    }
  }

  @media (max-width: 800px) {

    ${Button} {
      margin-top: 1.6rem;
    }

    h2 {
      font-size: 7vw;

      ::after {
        height: 7.2vw;
      }
    }

    .colors {
      margin: 0.8rem 0;
    }
  }

  @media (max-width: 450px) {
    width: 100%;

    ${Button} {
      margin-top: 2.4rem;
    }

    h2 {
      font-size: 14vw;

      ::after {
        height: 14.4vw;
      }
    }

    .colors {
      margin: 1.6rem 0;
    }
  }
`;

export default ProductCardStyle;
