export interface MainState {
  clientWidth: number;
  clientHeight: number;
}

export interface NavigationState {
  isHamburgerOpen: boolean;
  activeDropdownId?: string;
  indicatorPos: { width: number; left: number };
}

export interface ModalState {
  id?: string;
  onClose?: () => void;
}

export const initialMainState: MainState = {
  clientWidth: window.innerWidth,
  clientHeight: window.innerHeight,
};

export const initialNavigationState: NavigationState = {
  isHamburgerOpen: false,
  activeDropdownId: undefined,
  indicatorPos: { width: 0, left: 0 },
};

export const initialModalState: ModalState = {
  id: undefined,
  onClose: undefined,
};

export interface GlobalState {
  main: MainState;
  navigation: NavigationState;
  modal: ModalState;
}

export const initialState: GlobalState = ({
  main: initialMainState,
  navigation: initialNavigationState,
  modal: initialModalState,
});
