import styled from 'styled-components';
import { colors } from 'style/variables';

const ProductInfoStyle = styled.div`
  width: 29rem;
  display: flex;
  flex-direction: column;

  p {
    color: ${colors.black};
  }

  button {
    font-size: 1.2rem;
    color: ${colors.link};
    text-decoration: underline;
    cursor: pointer;
  }

  .top {
    display: flex;
    justify-content: space-between;
  }
`;

export default ProductInfoStyle;
