import styled from 'styled-components';
import { animated } from 'react-spring';
import getImageSizes from 'utils/getImageSizes';

export interface Props {
  image?: string;
  background?: string;
}

const BackgroundImage = styled(animated.div).attrs({ 'data-testid': 'image' })<Props>`
  ${({ image, background }): string => {
    if (image) {
      const images = getImageSizes(image);
      const sizes = Object.keys(images).map((size) => parseInt(size, 10));
      const largestSize = Math.max(...sizes);
      const queries = sizes.map((size) => (
        `@media (min-width: ${size}px) {
          background: ${background || ''}url(${images[size]});
          background-size: cover;
          background-position: center;
        }`
      ));

      return `
        background: ${background || ''}url(${images[largestSize]});
        background-size: cover;
        background-position: center;
        ${queries.join(' ')}
      `;
    }
    return '';
  }}
`;

export default BackgroundImage;
