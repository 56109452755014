import React, { FunctionComponent, useRef, ChangeEvent, useEffect } from 'react';
import { FormContextValues, FieldError } from 'react-hook-form';
import SelectInputStyle from './SelectInputStyle';

interface Option {
  name: string;
  value: string;
}

interface Props {
  register: FormContextValues['register'];
  errors: FormContextValues['errors'];
  watch: FormContextValues['watch'];
  id: string;
  label: string;
  options: Option[];
}

const SelectInput: FunctionComponent<Props> = ({ register, errors, watch, id, label, options }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const error: FieldError | undefined = errors[id];

  const addClassName = (className?: 'error' | 'success'): void => {
    if (containerRef.current) {
      containerRef.current.classList.remove('error', 'success');
      if (className) {
        containerRef.current.classList.add(className);
      }
    }
  };

  const onChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { value } = e.target;

    if (labelRef.current) {
      labelRef.current.innerText = (error?.message as string) || label;

      if (error) {
        addClassName('error');
      } else if (value === '') {
        addClassName();
      } else {
        addClassName('success');
      }
    }
  };

  useEffect(() => {
    if (watch()[id]) {
      addClassName('success');
    }
  }, [id, watch]);

  return (
    <SelectInputStyle>
      <div ref={containerRef}>
        <label ref={labelRef} htmlFor={id}>{label}</label>
        <select
          ref={register}
          id={id}
          name={id}
          onChange={onChange}
          defaultValue=""
          aria-invalid={!!error}
          aria-describedby={(error?.message as string)}
        >
          <option value="" className="placeholder"> </option>
          {options.map(({ name, value }) => (
            <option key={value} value={value}>{name}</option>
          ))}
        </select>
      </div>
    </SelectInputStyle>
  );
};

export default SelectInput;
