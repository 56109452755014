import styled from 'styled-components';

const AboveTheFold = styled.div`
  height: 100vh;
  min-height: 85rem;

  @media (max-width: 800px) {
    min-height: 50rem;
  }
`;

export default AboveTheFold;
