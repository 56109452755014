import styled from 'styled-components';
import SliderStyle from 'components/slider/SliderStyle';
import bgImg from 'assets/img/placeholder_card4_3000.jpg';

const ConceptPageDemoStyle = styled.section`

  .clip {
    width: 100%;
    height: 140%;
    clip-path: polygon(0 15%, 100% 0, 100% 85%, 0 100%);
    margin-bottom: 4.8rem;
    background-image: url(${bgImg});
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      width: 50%;
    }

    ${SliderStyle} {
      width: 50vw;
      max-width: 50rem;
      height: 50rem;

      margin-left: calc(50% - 25rem);
    }

    article {
      width: 50vw;
      max-width: 50rem;

      h3, p {
        width: 50vw;
        max-width: 50rem;
      }
    }

    h3, p {
      text-align: left;
      color: white;
    }

    h3 {
      width: 50rem;
    }

    p {
      width: 34.8rem;
    }

    img {
      margin-left: -35%;
    }

    .slider-controls {
      width: 34.8rem;
      margin-left: calc(50% - 25rem);
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 1200px) {
    .clip div:first-child img {
      width: 70vw;
      margin-left: -30vw;
    }
  }

  @media (max-width: 800px) {
    .clip {
      flex-direction: column;
      justify-content: center;

      div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin-left: 0;
        }
      }

      div:last-child {

        ${SliderStyle} {
          width: 50rem;
        
          .container {
            width: 50rem;
            justify-content: flex-start;
          }
        }

        article {
          width: 50rem;
          display: flex;
          flex-direction: column;
          align-items: center;

          h3, p {
            width: 50rem;
            text-align: center;
          }
        }

        .slider-controls {
          width: 50rem;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .clip {
      height: 180%;
      clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);

      div:last-child {
        width: 90vw;

        ${SliderStyle} {
          width: 90vw;
          margin-left: 0;

          .container {
            width: 100%;
          }
        }

        article {
          width: 90vw;

          h3, p {
            max-width: 90vw;
          }
        }

        .slider-controls {
          width: 90vw;
          margin-left: 0;
        }
      }
    }
  }
`;

export default ConceptPageDemoStyle;
