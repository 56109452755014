import { Action } from '../action';
import ActionTypes from '../types';

interface SetModalOptions {
  id?: string;
  onClose?: () => void;
}

export interface SetModalAction extends Action {
  id?: string;
  onClose?: () => void;
}

export const setModal = (data: SetModalOptions): SetModalAction => ({
  type: ActionTypes.SetModal,
  id: data.id,
  onClose: data.onClose,
});

export const isSetModalAction = (action: Action): action is SetModalAction => (
  action.type === ActionTypes.SetModal
);
