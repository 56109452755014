import styled from 'styled-components';
import SelectInputStyle from 'components/inputs/selectInput/SelectInputStyle';
import { colors } from 'style/variables';
import ModalContentStyle from '../ModalContentStyle';

const ShippingModalStyle = styled(ModalContentStyle)`

  ${SelectInputStyle} div {

    label {
      color: ${colors.black};
    }

    select {
      border-bottom: 1px solid ${colors.black};
    }
  }

  .input-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    margin-top: 2.4rem;

    p {
      min-width: 20.8rem;
      text-align: right;
    }
  }

  @media (max-width: 700px) {
    h3 {
      margin-bottom: 3.2rem;
    }
  }

  @media (max-width: 600px) {
    h3 {
      margin-bottom: 6.4rem;
    }

    .input-container {
      flex-direction: column;

      p {
        text-align: center;
      }
    }
  }
`;

export default ShippingModalStyle;
