/* eslint-disable @typescript-eslint/camelcase */
import React, { FunctionComponent, FormEvent, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { Sku } from 'assets/data/collection';
import * as yup from 'yup';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import countries from 'assets/data/countries';
import SelectInput from 'components/inputs/selectInput/SelectInput';
import Link from 'components/navigation/Link';
import Checkbox from 'components/inputs/checkbox/Checkbox';
import StripeInput from 'components/inputs/stripeInput/StripeInput';
import { StripeInputGroup } from 'components/inputs/stripeInput/StripeInputStyle';
import { TextInputGroup } from 'components/inputs/textInput/TextInputStyle';
import TextInput from 'components/inputs/textInput/TextInput';
import Button, { SecondaryButton } from 'style/Button';
import Color from 'style/Color';
import colors from 'assets/data/colors';
import regex from 'utils/regex';
import termsConditions from 'assets/pdf/Terms_Conditions.pdf';
import CheckoutFormStyle from './CheckoutFormStyle';

interface Props {
  sku: Sku;
  productName: string;
  setPaymentStatus: Dispatch<SetStateAction<string>>;
  onCancel(): void;
}

const validationSchema = yup.object().shape({
  firstname: yup.string().required('Firstname is required').matches(regex.isWord, 'Firstname contains invalid characters'),
  lastname: yup.string().required('Lastname is required').matches(regex.isWord, 'Lastname contains invalid characters'),
  email: yup.string().required('Email is required').email('This is not a valid email'),
  address: yup.string().required('Address is required')
    .matches(regex.containsWord, 'Address should contain a street name')
    .matches(regex.containsNumber, 'Address should contain a street number')
    .matches(regex.containsWhitespace, 'There should be a space between the street name and the number'),
  country: yup.string().required('Country is required').matches(regex.isWord, 'Country contains invalid characters'),
  city: yup.string().required('City is required').matches(regex.isWord, 'City contains invalid characters'),
  zip: yup.string().required('Zip is required').matches(regex.isNumber, 'ZIP should be a number'),
  terms: yup.bool().oneOf([true], 'Please accept the Terms & Conditions'),
});

const CheckoutForm: FunctionComponent<Props> = ({
  sku, productName, setPaymentStatus, onCancel,
}) => {
  const { register, handleSubmit, watch, errors, triggerValidation } = useForm({ mode: 'onChange', validationSchema });
  const [validElements, setValidElements] = useState<{ [element: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();
  const inputProps = { register, watch, errors };
  const color = sku && colors.find((c) => c.name === sku.attributes.color);
  const currencies: { [key: string]: string } = { eur: '€' };
  const stripeStatus = Object.values(validElements);
  const isStripeElementsValid = stripeStatus.length === 3 && !stripeStatus.includes(false);
  const isValid = Object.keys(errors).length === 0 && isStripeElementsValid;
  const isTouched = Object.keys(watch()).length !== 0;
  const shippingAmount = countries.find(({ name }) => name === watch('country'))?.shippingRate;

  const price = (amount: number): string => `${currencies[sku.currency]}${amount / 100}`;

  const setIsValid = (status: boolean, element: string): void => {
    setValidElements({ ...validElements, [element]: status });
  };

  const pay = async (clientSecret: string): Promise<void> => {
    if (stripe && elements) {
      setIsLoading(true);
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement('cardNumber') || { token: '' },
        },
      });

      if (result) {
        setIsLoading(false);
        if (result.paymentIntent) { setPaymentStatus(result.paymentIntent.status); }
        if (result.error) { setPaymentStatus(result.error.code || 'error'); }
      }
    }
  };

  const onSubmit = (data: Record<string, string>, e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (isValid) {
      fetch('/api/secret', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          name: productName,
          size: sku.attributes.size,
          color: sku.attributes.color,
        }),
      })
        .then((response) => response.json())
        .then(({ client_secret }) => pay(client_secret));
    }
  };

  useEffect(() => {
    triggerValidation();
  }, [triggerValidation]);

  return (
    <CheckoutFormStyle>
      <div className="order-info">
        <SecondaryButton dark back to="#" onClick={onCancel}>Cancel</SecondaryButton>
        <h4>{`Size ${sku.attributes.size}`}</h4>
      </div>
      <div className="order-info">
        <h3>{productName}</h3>
        <Color dark active color={color && color.color} />
      </div>
      <form>
        <p>Cardholder</p>
        <TextInputGroup amount={2}>
          <TextInput {...inputProps} id="firstname" label="firstname" placeholder="John" />
          <TextInput {...inputProps} id="lastname" label="lastname" placeholder="Johnson" />
        </TextInputGroup>
        <TextInput {...inputProps} id="email" label="email" type="email" placeholder="john.johnson@tirette.com" />
        <p>Payment details</p>
        <StripeInput id="card-number" label="card number" setIsValid={(v: boolean): void => setIsValid(v, 'card-number')} />
        <StripeInputGroup amount={2}>
          <StripeInput id="card-expiry" label="expiry date" setIsValid={(v: boolean): void => setIsValid(v, 'card-expiry')} />
          <StripeInput id="card-cvc" label="CVC" setIsValid={(v: boolean): void => setIsValid(v, 'card-cvc')} />
        </StripeInputGroup>
        <p>Shipping address</p>
        <TextInput {...inputProps} id="address" label="street + nr." placeholder="new street 123" />
        <TextInputGroup amount={3}>
          <SelectInput {...inputProps} id="country" label="country" options={countries.map(({ name }) => ({ name, value: name }))} />
          <TextInput {...inputProps} id="city" label="city" placeholder="Bruges" />
          <TextInput {...inputProps} id="zip" label="zip" type="number" placeholder="8000" />
        </TextInputGroup>
        <Checkbox
          {...inputProps}
          id="terms"
          label={(
            <p>
              I accept the&nbsp;
              <Link className="terms-link" to={termsConditions}>Terms &amp; Conditions.</Link>
            </p>
          )}
        />
        <Button dark to="#" onClick={handleSubmit(onSubmit)} disabled={!(isTouched && isValid) || isLoading}>{`Pay ${price(sku.price)}`}</Button>
      </form>
      <p className="shipping-info">{shippingAmount ? `${price(shippingAmount)} shipping will apply.` : ''}</p>
    </CheckoutFormStyle>
  );
};

export default CheckoutForm;
