import styled from 'styled-components';
import { colors, fonts } from 'style/variables';

const SelectInputStyle = styled.div`
  margin-bottom: 1.6rem;
  font-family: ${fonts.secondary};
  font-size: 1.4rem;
  color: ${colors.black};

  div {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
      margin-top: 2rem;
      margin-bottom: -2rem;
      margin-left: .8rem;
      color: ${colors.disabled};
      pointer-events: none;
      will-change: margin-top, margin-bottom, color, font-size;
      transition: margin-top .2s ease, margin-bottom .2s ease, color .2s ease, font-size .2s ease;
    }

    select {
      appearance: none;
      outline: none;
      padding-left: .8rem;
      border: none;
      border-bottom: 1px solid ${colors.disabled};
      border-radius: 0px;
      font-family: inherit;
      font-size: inherit;
      padding-top: 2.3rem;
      height: .4rem;
      color: transparent;
      background-color: transparent;
      will-change: height, border-bottom, padding-top;
      transition: height .2s ease, padding-top .2s ease, border-bottom .2s ease;

      .placeholder {
        display: none;
      }
    }
  }

  .focus, .error, .success {
    label {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1.2rem;
    }

    select {
      height: 2.4rem;
      padding-top: .3rem;
      color: ${colors.black};
    }
  }

  .error {
    label {
      color: ${colors.error};
    }

    select {
      border-bottom: 1px solid ${colors.error};
    }
  }

  .success {
    label {
      color: ${colors.success};
    }

    select {
      border-bottom: 1px solid ${colors.success};
    }
  }
`;

export default SelectInputStyle;
