import React, { FunctionComponent, useEffect, useRef } from 'react';
import { SecondaryButton } from 'style/Button';
import debounce from 'utils/debounce';
import NewspaperCardStyle from './NewspaperCardStyle';

interface Props {
  title: string;
  subtitle: string;
  body: string;
  url: string;
}

const NewspaperCard: FunctionComponent<Props> = ({ title, subtitle, body, url }) => {
  const bodyRef = useRef<HTMLDivElement>(null);

  const wrapText = (container: HTMLDivElement): void => {
    const text = container.querySelector('p');
    const containerHeight = container.clientHeight;

    if (text) {
      text.textContent = body as string | null;

      while (text.offsetHeight > (containerHeight * 0.90)) {
        if (text.textContent) {
          text.textContent = text.textContent.replace(/\W*\s(\S)*$/, '...');
        }
      }
    }
  };

  const handleResize = debounce(() => {
    if (bodyRef.current) {
      wrapText(bodyRef.current);
    }
  }, 100, true);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <NewspaperCardStyle>
      <div className="content">
        <header>
          <h3>{title}</h3>
          <h4>{subtitle}</h4>
        </header>
        <div className="intro" ref={bodyRef}>
          <p>{body}</p>
          <SecondaryButton forward dark to={url}>Read more</SecondaryButton>
        </div>
      </div>
    </NewspaperCardStyle>
  );
};

export default NewspaperCard;
