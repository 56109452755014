import React, { FunctionComponent, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import Track from 'lib/tracker';
import ErrorPage from 'components/pages/errorPage/ErrorPage';
import HomeHeader from 'components/pages/homePage/HomeHeader';
import ProductHeader from 'components/pages/productPage/ProductHeader';
import ConceptHeader from 'components/pages/conceptPage/ConceptHeader';
import HomePage from 'components/pages/homePage/HomePage';
import ProductPage from 'components/pages/productPage/ProductPage';
import ConceptPage from 'components/pages/conceptPage/ConceptPage';

interface Page {
  [page: string]: {
    header: JSX.Element;
    page: JSX.Element;
  };
}

const Routes: FunctionComponent = () => {
  const history = useHistory();

  const renderPage = (key: string): JSX.Element => {
    const page: Page = {
      home: { header: <HomeHeader />, page: <HomePage /> },
      product: { header: <ProductHeader />, page: <ProductPage /> },
      concept: { header: <ConceptHeader />, page: <ConceptPage /> },
    };

    return (
      <>
        {page[key].header}
        {page[key].page}
      </>
    );
  };

  useEffect(() => {
    Track.pageview(window.location.pathname);
    history.listen(() => {
      Track.pageview(window.location.pathname);
    });
  }, [history]);

  return (
    <Switch>
      <Route exact path="/" render={(): JSX.Element => renderPage('home')} />
      <Route path="/collection/:id" render={(): JSX.Element => renderPage('product')} />
      <Route path="/concept" render={(): JSX.Element => renderPage('concept')} />
      <Route render={(): JSX.Element => <ErrorPage id="404" />} />
    </Switch>
  );
};

export default Routes;
