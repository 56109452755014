import React, { useState, FunctionComponent, useEffect, ChangeEvent } from 'react';

import useGlobalState from 'hooks/globalState/useGlobalState';
import { toggleHamburger as toggleHamburgerAction } from 'hooks/globalState/actions/navigationActions/toggleHamburger';
import useDispatch from 'hooks/globalState/useDispatch';
import useOnClose from 'hooks/useOnClose';
import HamburgerStyle, { HamburgerDropdown } from './HamburgerStyle';
import { Link as ILink } from '../header/Header';
import Link from '../Link';

interface Props {
  links: ILink[];
}

const Hamburger: FunctionComponent<Props> = ({ links }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean[]>([]);
  const { isHamburgerOpen } = useGlobalState('navigation');
  const toggleHamburger = useDispatch(toggleHamburgerAction);
  const parent = useOnClose<HTMLUListElement>(() => toggleHamburger({ isHamburgerOpen: false }));

  const onHamburgerClick = (e: ChangeEvent<HTMLInputElement>): void => {
    setIsDropdownOpen(isDropdownOpen.fill(false));
    toggleHamburger({ isHamburgerOpen: e.currentTarget.checked });
  };

  const onNavigate = (link: ILink, index: number): void => {
    if (link.dropdown) {
      const newDropdownOpen = [...isDropdownOpen].fill(false);
      newDropdownOpen[index] = !isDropdownOpen[index];
      setIsDropdownOpen(newDropdownOpen);
    }
  };

  useEffect(() => {
    toggleHamburger({ isHamburgerOpen: false });
  }, [toggleHamburger]);

  return (
    <HamburgerStyle>
      <label htmlFor="hamburger" className="screenreader-only">Menu</label>
      <input type="checkbox" id="hamburger" checked={isHamburgerOpen} onChange={onHamburgerClick} />
      <span />
      <span />
      <span />
      <ul
        className="hamburger-navigation"
        ref={parent}
        style={{ opacity: isHamburgerOpen ? 1 : 0 }}
      >
        {links.map((link, i) => (
          <li key={link.page}>
            {link.dropdown && (
              <>
                <HamburgerDropdown isOpen={isDropdownOpen[i]}>
                  {link.dropdown.map((dropdownLink) => (
                    <li key={dropdownLink.page}>
                      <Link to={dropdownLink.url}>{dropdownLink.page}</Link>
                    </li>
                  ))}
                </HamburgerDropdown>
                <button
                  type="button"
                  data-number={`0${i + 1}`}
                  onClick={(): void => onNavigate(link, i)}
                >
                  {link.page}
                </button>
              </>
            )}
            {!link.dropdown && (
            <Link to={link.url || '#'} data-number={`0${i + 1}`}>{link.page}</Link>
            )}
          </li>
        ))}
      </ul>
    </HamburgerStyle>
  );
};

export default Hamburger;
