import styled from 'styled-components';
import importAll from 'utils/importAll';
import { colors, fonts } from 'style/variables';

const SizeGuideStyle = styled.div`
  height: 100%;
  overflow: scroll;

  h3 {
    margin-top: 4rem;
    color: ${colors.black};
    text-align: center;
  }

  .container {
    display: flex;
    margin: 3.2rem 2.4rem 0 2.4rem;

    .image-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 40%;

      img {
        width: 19.2rem;
      }

      .caution {
        color: ${colors.black};
        margin-top: 2.4rem;
        display: flex;
        align-items: center;

        ::before {
          content: url(${importAll(require.context('../../../../assets/svg', false, /\.svg$/), 'caution_icon.svg')[0]});
          margin-right: .8rem;
        }
      }
    }

    table {
      color: ${colors.black};
      font-family: ${fonts.primary};
      font-size: 1.4rem;
      line-height: 2.08;

      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      tr {
        display: flex;
        justify-content: space-between;
      }

      th {
        font-size: 1.8rem;
      }

      th, td {
        text-align: center;
        min-width: 8rem;
      }
    }
  }

  @media (max-width: 700px) {
    h3 {
      margin-top: 2rem;
    }

    .container {
      margin: 2.4rem 1.6rem 0 1.6rem;
    }
  }

  @media (max-width: 600px) {
    h3 {
      margin-top: 4rem;
    }

    .container {
      flex-direction: column;
      align-items: center;
      margin: 3.2rem 2.4rem 0 2.4rem;

      .image-container {
        margin-left: 0rem;
        margin-bottom: 2.4rem;
        width: 100%;
      }

      table {
        width: 100%;
      }
    }
  }
`;

export default SizeGuideStyle;
