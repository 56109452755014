import styled from 'styled-components';
import { colors } from 'style/variables';
import Card from 'style/Card';
import ProductInfoStyle from 'components/webshop/productInfo/ProductInfoStyle';
import AboveTheFold from 'style/AboveTheFold';
import CustomizationFormStyle from 'components/webshop/customizationForm/CustomizationFormStyle';
import CheckoutFormStyle from 'components/webshop/checkoutForm/CheckoutFormStyle';

const ProductPageStyle = styled.section`

  ${AboveTheFold} {
    width: 100vw;
    display: flex;
    align-items: center;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  };

  ${CustomizationFormStyle}, ${CheckoutFormStyle} {
    margin-left: 5.6rem;

    @media (max-width: 800px) {
      position: relative;
      margin: -8rem auto 0;
      z-index: 1;
    }
  }

  .header-image-container {
    width: 66vw;
    height: 100%;
    min-height: inherit;
    position: relative;

    .slider-container, .image-container {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: inherit;
    }

    .slider-animated-container, .image-animated-container {
      position: absolute;
      width: 100%;
      height: 100%;
      min-height: inherit;
      will-change: opacity;
    }

    .image-animated-container {
      display: flex;
      justify-content: space-between;
    }

    .slider-controls {
      display: flex;
      justify-content: center;
      width: calc(100vw - 40.2rem);
      position: absolute;
      top: calc(100% - 8.4rem);
    }

    @media (max-width: 1200px) {
      width: calc(100vw - 40.2rem);
    }

    @media (max-width: 800px) {
      width: 100vw;

      .slider-controls {
        width: 100vw;
        top: calc(100% - 12rem);
      }
    }
  }

  .header-image {
    width: calc(33vw - 0.4rem);
    height: 100%;
  }

  .slide-image {
    min-width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .cards {
    display: flex;
    position: relative;

    .card-container {
      width: 42.4rem;
      height: 50vw;
      max-height: 68.8rem;
      position: relative;

      :nth-child(2) {
        margin-top: -2.8rem;
        order: 1;
      }

      :nth-child(3) {
        margin-top: -5.2rem;
        order: 3;
      }

      :last-child {
        margin: 20.4rem -2rem 0;
        order: 2;
      }

      ::after {
        content: '';
        display: block;
        width: 98%;
        height: 85%;
        box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.2);
        left: 1%;
        bottom: 0;
        z-index: -1;
        position: absolute;
      }

      ${Card} {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: absolute;
        will-change: opacity;

        ::after {
          display: none;
        }

        p {
          width: 50%;
          font-weight: bold;
          font-size: 2.4rem;
          text-transform: uppercase;
          text-align: center;
          margin-bottom: 1rem;

          @media (max-width: 1000px) {
            width: 70%;
          }

          @media (max-width: 900px) {
            width: 90%;
          }
        }
      }
    }

    .quote {
      width: 24%;
      margin: 0 38%;
      position: absolute;
      font-family: 'Georgia';
      font-style: italic;
      font-size: 4rem;
      line-height: 1.618;
      color: ${colors.black};
      text-align: center;
      margin-top: -4rem;
    }
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 8.8rem 0;

    @media (max-width: 800px) {
      flex-direction: column;

      ${ProductInfoStyle} {
        margin-top: 4rem;
      }
    }
  }
`;

export default ProductPageStyle;
