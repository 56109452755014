import { SpringConfig } from 'react-spring';

export const transition: SpringConfig = {
  mass: 1,
  tension: 210,
  friction: 23,
};

export const hover: SpringConfig = {
  mass: 1,
  tension: 210,
  friction: 20,
};
