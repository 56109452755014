import React, { FunctionComponent, useState } from 'react';
import { FormContextValues, FieldError } from 'react-hook-form';
import CheckboxStyle from './CheckboxStyle';

interface Props {
  register: FormContextValues['register'];
  errors: FormContextValues['errors'];
  id: string;
  label: string | JSX.Element;
}

const Checkbox: FunctionComponent<Props> = ({ register, errors, id, label }) => {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const error: FieldError = errors[id];
  const hasError = !!(isTouched && error);

  return (
    <CheckboxStyle htmlFor={id} onClick={(): void => setIsTouched(true)} error={hasError}>
      <p className="error">{isTouched && error?.message}</p>
      {label}
      <input
        ref={register}
        type="checkbox"
        id={id}
        name={id}
        aria-invalid={!!error}
        aria-describedby={(error?.message as string)}
      />
      <span className="checkmark" />
    </CheckboxStyle>
  );
};

export default Checkbox;
